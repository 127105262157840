import { createAction, props } from '@ngrx/store';
import { JWTSession } from './root-store.model';

export const initialization = createAction(
  '[Root Coupon Platform] Initialization'
);

export const saveAuthorizationCode = createAction(
  '[Root Coupon Platform] Save Authorization Code',
  props<{ authorizationCode: string }>()
);

export const saveJWTSession = createAction(
  '[Root Coupon Platform] Save JWT Session',
  props<{ jwtSession: JWTSession }>()
);

export const saveWebToken = createAction(
  '[Root Coupon Platform] Save Web Token',
  props<{ webToken: string, jwtSession: JWTSession }>()
);

export const saveAccessToken = createAction(
  '[Root Coupon Platform] Save Access Token',
  props<{ accessToken: string; }>()
);

export const postAuthVerify = createAction(
  '[Root Coupon Platform] Post Auth Verify',
  props<{ authorizationCode: string, webConfig: string }>()
);

export const saveCouponChannel = createAction(
  '[Root Coupon Platform] Save couponChannel',
  props<{ couponChannel: string }>()
);



