import { Injectable } from "@angular/core";
import { RequestService } from "../http/request.service";
import { map, Observable, shareReplay } from "rxjs";
import { ICouponResponse, IFetchCollectCoupon, IFetchCouponDetail, IFetchReseverCouponOrder } from "../models/core.model";
import { Mode } from "../configs/mode-enum-config";

@Injectable({ providedIn: 'root' })
export class CoreService {
    constructor(private requestService: RequestService) { }

    fetchCouponDetail(couponId: any, couponOrder: number, mode?: string, amount?: string, fromPage?: string): Observable<ICouponResponse<IFetchCouponDetail>> {
        return this.requestService.send<ICouponResponse<IFetchCouponDetail>>(
            'GET',
            `/api/v1/coupons/${couponId}?couponOrder=${couponOrder}&mode=${mode === Mode.CANCEL ? 'cancelCopayPayment' : ''}${amount ? `&amount=${amount}` : ''}${fromPage ? `&fromPage=${fromPage}` : ''}`,
        ).pipe(
            map((response: ICouponResponse<IFetchCouponDetail>) => { return response }),
            shareReplay(1)
        );
    }

    fetchCollectCoupon(couponId: string): Observable<ICouponResponse<IFetchCollectCoupon>> {
        return this.requestService.send<ICouponResponse<IFetchCollectCoupon>>(
            'POST',
            `/api/v1/coupons/collect/${couponId}`,
        ).pipe(
            map((response: ICouponResponse<IFetchCollectCoupon>) => { return response }), shareReplay(1)
        );
    }

    reseverCouponOrder(couponId: string, couponOrder: number, mode?: string): Observable<ICouponResponse<IFetchReseverCouponOrder>> {
        return this.requestService.send<ICouponResponse<IFetchReseverCouponOrder>>(
            'POST',
            `/api/v1/coupons/reserve-order`,
            { campaignId: couponId, receivedOrder: couponOrder, mode: mode },
        ).pipe(
            map((response: ICouponResponse<IFetchReseverCouponOrder>) => { return response })
        );
    }
}