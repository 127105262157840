import { Component, Input, OnInit } from '@angular/core';
import { CouponStatus } from '@core/models/core-enum.model';

@Component({
  selector: 'mp-coupon-card-image',
  templateUrl: './coupon-card-image.component.html',
  styleUrls: ['./coupon-card-image.component.scss']
})
export class CouponCardImageComponent implements OnInit {
  @Input() index: number;
  @Input() prefixId: string;
  @Input() couponImage: string;
  @Input() couponStatus: string;
  @Input() displayImageShadow: boolean = false;
  CouponStatus = CouponStatus;
  constructor() { }

  ngOnInit(): void {
  }

}
