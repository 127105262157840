import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mp-animation-loading',
  templateUrl: './animation-loading.component.html',
  styleUrls: ['./animation-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnimationLoadingComponent implements OnInit {
  @Input() couponloading: boolean | null;
  @Input() loadingAnimation: boolean | null;
  constructor() { }

  ngOnInit(): void {

  }

}