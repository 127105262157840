import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppState } from "../../root-store/root-store.model";
import * as CoreCouponSelectors from '@core/store/core.selector';

@Injectable({ providedIn: 'root' })
export class RouterService {
    private stepRouteConfig$ = this.store.select(CoreCouponSelectors.selectStepRouteConfig);
    private history: string[] = [];
    constructor(
        private router: Router,
        private store: Store<AppState>
    ) {
        this.stepRouteConfig$.subscribe((route: string[]) => {
            this.history = route;
        });
    }

    goTo(route: string, options: NavigationExtras = {}) {
        this.router.navigate([route], options);
    }

    back(options: NavigationExtras = {}) {
        if (this.history.length > 1) {
            this.goTo(this.history[this.history.length - 2], options)
        }
    }

    isPreviousPath(path: string, previouPathIndex = 2) {
        return this.history[this.history.length - previouPathIndex] === path;
    }

    getPreviousPath(previouPathIndex = 2) {
        return this.history[this.history.length - previouPathIndex];
    }

    isFirstPath() {
        return this.history.length <= 1;
    }
}