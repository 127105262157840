import { createFeatureSelector, createSelector } from "@ngrx/store"
import {
    SearchCouponState,
    SEARCH_COUPON_STATE,
} from '../models/search-coupon-state.model';

export const selectState = createFeatureSelector<SearchCouponState>(SEARCH_COUPON_STATE);

export const selectIsLoading = createSelector(
    selectState,
    (state) => state.isLoading
);

export const selectSearchKeyword = createSelector(
    selectState,
    (state) => state.keyword
);

export const selectDefaultSearchKeyword = createSelector(
    selectState,
    (state) => state.defaultSearchKeyword
);

export const selectDefaultSearchKeywordIndex = createSelector(
    selectState,
    (state) => state.defaultSearchKeywordIndex
);

export const selectCouponSearchResult = createSelector(
    selectState,
    (state) => state.searchResult
);