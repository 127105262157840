import { CoreCouponState } from '../core/models/core-state.model';
import { ApplyCouponState } from '../features/apply-coupon/models/apply-coupon-state.model';
import { CouponChooserState } from '../features/coupon-chooser/models/coupon-chooser-state.model';
import { CouponDetailState } from '../features/coupon-detail/models/coupon-detail-state.model';
import { CouponHomeState } from '../features/coupon-home/models/coupon-home-state.model';
import { MyCouponState } from '../features/my-coupon/models/my-coupon-state.model';
import { QRCodeState } from '../features/qr-code/models/qr-code-state.model';
import { SearchCouponState } from '../features/search-coupon/models/search-coupon-state.model';
import { CouponState } from '../model/coupon-state.model';
export const COUPON_STATE = 'counpon';
export const CORE_COUPON_STATE = 'core-coupon';
export const COUPON_HOME_STATE = 'coupon-home';
export const MY_COUPON_STATE = 'my-coupon';
export const QR_CODE_STATE = 'qr-code';
export const COUPON_CHOOSER_STATE = 'coupon-chooser';
export const COUPON_DETAIL_STATE = 'coupon-detail';
export const APPLY_COUPON_STATE = 'appy-coupon';
export const SEARCH_COUPON_STATE = 'search-coupon';

export interface AppState {
  session: Session;
  [CORE_COUPON_STATE]: CoreCouponState;
  [COUPON_HOME_STATE]: CouponHomeState;
  [MY_COUPON_STATE]: MyCouponState;
  [COUPON_CHOOSER_STATE]: CouponChooserState;
  [COUPON_DETAIL_STATE]: CouponDetailState;
  [QR_CODE_STATE]: QRCodeState;
  [SEARCH_COUPON_STATE]: SearchCouponState;
  [APPLY_COUPON_STATE]: ApplyCouponState;
}

export interface Session {
  isInitialised: boolean;
  authorizationCode: string;
  webToken: string;
  accessToken: string;
  jwtSession: JWTSession;
}

export interface JWTSession {
  couponChannel: string;
  callbackUrl: string;
  registerCallbackUrl: string;
  processingCallbackUrl: string;
  paymentCallbackUrl: string;
  chooseCouponCallbackUrl: string;
  withHeader: undefined | boolean;
  status: string;
  mode: string;
  webTokenId: string;
  sourceOfFund: string;
  callbackDestination: string;
  entryPoint: string;
  paymentMethod: string;
  userSOF: string[];
}

export interface ICouponAccressToken {
  accessToken: string;
}
