import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CopayNavigationType, CouponMethod, CouponStatus, InternalNavigationType, PeriodStatus } from '@core/models/core-enum.model';
import { ICouponCopayNavigation } from '@core/models/core.model';

@Component({
  selector: 'mp-coupon-card-button',
  templateUrl: './coupon-card-button.component.html',
  styleUrls: ['./coupon-card-button.component.scss']
})
export class CouponCardButtonComponent implements OnInit {
  @Input() index: number;
  @Input() prefixCouponId: string;
  @Input() couponStatus: string;
  @Input() periodStatus: string;
  @Input() numberOfCoupons: number;
  @Input() couponUseMethod: string;
  @Input() copayNavigation: ICouponCopayNavigation;
  @Input() isInChannel: boolean = false;
  @Input() isUseButtonDisplay: boolean = false;
  @Input() isCoPayNavigate: boolean = false;
  @Input() buttonInOverlay: boolean = false;
  @Input() isApplyCouponQRCodeONCouponList: boolean = false;
  @Input() isCopayPassFromCouponList: boolean = false;

  @Output() onActionPickCoupon: EventEmitter<any> = new EventEmitter<void>();
  @Output() onActionUseCoupon: EventEmitter<any> = new EventEmitter<void>();
  @Output() onActionUseCouponCopay: EventEmitter<any> = new EventEmitter<void>();
  @Output() onActionUseCouponPaymentTopup: EventEmitter<any> = new EventEmitter<void>();
  @Output() onActionUseCouponExternalURL: EventEmitter<any> = new EventEmitter<void>();
  @Output() onActionOpenOverlay: EventEmitter<any> = new EventEmitter<void>();
  CouponStatus = CouponStatus;
  CouponMethod = CouponMethod;
  CopayNavigationType = CopayNavigationType;
  InternalNavigationType = InternalNavigationType;
  PeriodStatus = PeriodStatus;
  constructor() { }

  ngOnInit(): void {
  }

  openOverlay() {
    this.onActionOpenOverlay.emit();
  }

  pickCoupon() {
    this.onActionPickCoupon.emit();
  }

  useCoupon() {
    this.onActionUseCoupon.emit();
  }

  useScanCopayCoupon() {
    this.onActionUseCouponCopay.emit();
  }

  usePaymentTopup() {
    this.onActionUseCouponPaymentTopup.emit();
  }

  navigateExternalURL() {
    this.onActionUseCouponExternalURL.emit();
  }

}
