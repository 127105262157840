<ng-container *transloco="let t">
    <div *ngIf="couponloading || loadingAnimation" data-testid="animation-loading" class="spinner"
        [class.spinner-load-coupon]="loadingAnimation">
        <table>
            <tr>
                <img class="loading-img" src="../../../../assets/images/CouponLoading.gif" width="80">
            </tr>
            <tr *ngIf="couponloading">
                <td>
                    <span class="loading-font">{{ t('animation_laoding_collect_coupon') }}</span>
                    <span class="typed-out">...</span>
                </td>
            </tr>
            <tr *ngIf="loadingAnimation">
                <td>
                    <span class="loading-font">{{ t('animation_laoding_coupon') }}</span>
                    <span class="typed-out category">...</span>
                </td>
            </tr>
        </table>
    </div>
</ng-container>