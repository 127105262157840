import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../root-store/root-store.model';
import * as RootStoreSelector from '../../root-store/root-selectors';
import { first, mergeMap } from 'rxjs/operators';
import { isEmpty } from "lodash";


@Injectable({
    providedIn: 'root',
})
export class HeaderInjector implements HttpInterceptor {
    authenUrl = 'token/auth/core-web';
    constructor(private store: Store<AppState>) { }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        return this.store.select(RootStoreSelector.selectSession).pipe(
            first((v) => Boolean(v.authorizationCode) || Boolean(v.webToken) || Boolean(v.accessToken)),
            mergeMap(({ webToken, accessToken, jwtSession }) => {

                if (req.url.includes(this.authenUrl)) {
                    const handledAuthReq = req.clone({
                        headers: req.headers
                            .set('Content-Type', 'application/json')
                    });
                    return next.handle(handledAuthReq);
                }

                const codeToken = isEmpty(accessToken) ? webToken : '';
                const handledReq = req.clone({
                    headers: req.headers
                        .set('Content-Type', 'application/json')
                        .set('Authorization', 'Bearer ' + accessToken)
                        .set('X-Auth-Code', codeToken)
                        .set('web-token-id', jwtSession?.webTokenId ?? '')
                        .set('Cache-Control', 'no-store')
                });

                return next.handle(handledReq);
            })
        );
    }
}