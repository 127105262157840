import { Injectable } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { JWTSession } from "../root-store/root-store.model";

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) { }

    parseJwt = (token: string) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
        if (!base64) return '';
        var jsonPayload = decodeURIComponent(
            atob(base64)
                ?.split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    };

    praseJWTWebCore(parseWebToken: any) {
        const jwtSession: JWTSession = {
            couponChannel: parseWebToken.channelId,
            withHeader: true,
            status: parseWebToken.status,
            registerCallbackUrl: parseWebToken.registerCallbackUrl ?? "",
            processingCallbackUrl: parseWebToken.processingCallbackUrl ?? "",
            paymentCallbackUrl: parseWebToken.paymentCallbackUrl ?? "",
            chooseCouponCallbackUrl: parseWebToken.chooseCouponCallbackUrl ?? "",
            callbackUrl: parseWebToken.callbackUrl ?? "",
            mode: parseWebToken.mode ?? "",
            webTokenId: parseWebToken.webTokenId ?? "",
            sourceOfFund: parseWebToken.sourceOfFund ?? "",
            callbackDestination: parseWebToken.callbackDestination ?? "",
            entryPoint: parseWebToken.entryPoint ?? "",
            paymentMethod: parseWebToken.paymentMethod ?? "",
            userSOF: parseWebToken.userSOF ?? []
        };
        return jwtSession;
    }

    decodeValidURIComponent(keyword: string) {
        try {
            const decodeKeyword = decodeURIComponent(keyword);
            return decodeKeyword;
        } catch (e) {
            return keyword;
        }
    }

    removeTokenSession() {
        sessionStorage.removeItem('codeToken');
        sessionStorage.removeItem('accessToken');
    }

    removeWebTokenUrl() {
        if (this.activatedRoute?.snapshot?.queryParams['code'] ||
            this.activatedRoute?.snapshot?.queryParams['authorizationCode']
        ) {
            const params: Params = {
                code: null,
                authorizationCode: null,
                webConfig: null
            };
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: params,
                queryParamsHandling: 'merge', // remove to replace all query params by provided
                replaceUrl: true
            });
        }
    }
}
