import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

declare var gtag: Function;

@Component({
  selector: 'mp-bottom-content-slider-dialog',
  templateUrl: './bottom-content-slider-dialog.component.html',
  styleUrls: ['./bottom-content-slider-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class BottomContentSliderDialogComponent implements OnInit {
  @ContentChild('header', { static: true, read: TemplateRef })
  headerTemplate!: TemplateRef<any>;

  @ContentChild('content', { static: true, read: TemplateRef })
  contentTemplate!: TemplateRef<any>;

  @ContentChild('button', { static: true, read: TemplateRef })
  buttonTemplate!: TemplateRef<any>;

  @Input() display = false;
  @Input() headerLeft = false;
  @Input() headerTitle = '';
  @Input() isClosed = false;
  @Input() hideOnOutsideClick = false;
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter();
  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  closeEvt(): void {
    this.onClose.emit();
    this.displayChange.emit(false);
  }
}
