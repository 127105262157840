import { Component, Input, OnInit } from '@angular/core';
import { CouponStatus, PeriodStatus } from '@core/models/core-enum.model';
import { convertToLocaleDateString, convertToLocaleTimeString } from '@utils/helpers/date-time.helper';

@Component({
  selector: 'mp-coupon-card-bottom-description',
  templateUrl: './coupon-card-bottom-description.component.html',
  styleUrls: ['./coupon-card-bottom-description.component.scss']
})
export class CouponCardBottomDescriptionComponent implements OnInit {
  @Input() isInChannel: boolean = false;
  @Input() index: number;
  @Input() couponStatus: string;
  @Input() periodStatus: string;
  @Input() couponStartDateTime: string;
  @Input() couponEndDateTime: string;
  @Input() couponStartRedemptionDatetime: string;
  @Input() couponEndRedemptionDateTime: string;
  @Input() redeemedDate: string | undefined;
  @Input() segmentErrorMessage: string;
  @Input() isApplyCouponQRCodeONCouponList: boolean = false;
  CouponStatus = CouponStatus;
  PeriodStatus = PeriodStatus;
  constructor() { }

  ngOnInit(): void {
  }

  convertDateFormat(val: any) {
    const date = new Date(val)
    return convertToLocaleDateString(date);
  }

  convertTimeFormat(val: any) {
    const date = new Date(val)
    return convertToLocaleTimeString(date);
  }

}
