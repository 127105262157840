import { LOCALE_ID, NgModule } from '@angular/core';
import { Location } from '@angular/common';
import {
    TRANSLOCO_LOADER,
    TRANSLOCO_CONFIG,
    TranslocoModule,
    TranslocoService,
} from '@ngneat/transloco';

import { Store } from '@ngrx/store';
import { languagePackFactory, TranslocoHttpLoader } from './i18n.loader';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    exports: [TranslocoModule],
    imports: [HttpClientModule],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useFactory: languagePackFactory,
            deps: [Location, Store],
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
        {
            provide: LOCALE_ID,
            useFactory: (translocoService: TranslocoService) => {
                return translocoService.getActiveLang();
            },
            deps: [TranslocoService],
        },
    ],
})
export class I18nModule { }