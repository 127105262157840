import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageRoute } from '@core/routers/route-config';

const routes: Routes = [
  { path: '', redirectTo: PageRoute.HOME, pathMatch: 'full' },
  {
    path: PageRoute.HOME,
    loadChildren: () => import('./features/coupon-home/coupon-home.module').then(
      (m) => m.CouponHomeModule
    )
  },
  {
    path: PageRoute.MY_COUPON,
    loadChildren: () => import('./features/my-coupon/my-coupon.module').then(
      (m) => m.MyCouponModule
    )
  },
  {
    path: PageRoute.COUPON_LIST,
    loadChildren: () => import('./features/coupon-chooser/coupon-chooser.module').then(
      (m) => m.CouponChooserModule
    )
  },
  {
    path: PageRoute.COUPON_DETAIL,
    loadChildren: () => import('./features/coupon-detail/coupon-detail.module').then(
      (m) => m.CouponDetailModule
    )
  },
  {
    path: PageRoute.QR_CODE,
    loadChildren: () => import('./features/qr-code/qr-code.module').then(
      (m) => m.QRCodeModule
    )
  },
  {
    path: PageRoute.SEARCH_COUPON,
    loadChildren: () => import('./features/search-coupon/search-coupon.module').then(
      (m) => m.SearchCouponModule
    )
  },
  {
    path: PageRoute.APPLY_COUPON,
    loadChildren: () => import('./features/apply-coupon/apply-coupon.module').then(
      (m) => m.ApplyCouponModule
    )
  },
  {
    path: PageRoute.UNDER_MAINTENANCE,
    loadChildren: () => import('./shared/pages/under-maintenance/under-maintenance-routing.module').then(
      (m) => m.UnderMaintenanceRoutingModule
    )
  },
  {
    path: PageRoute.NOT_FOUND,
    loadChildren: () => import('./shared/pages/not-found/not-found-routing.module').then(
      (m) => m.NotFoundRoutingModule
    )
  },
  {
    path: PageRoute.UNAUTHORIZED,
    loadChildren: () => import('./shared/pages/unauthorized/unauthorized-routing.module').then(
      (m) => m.UnauthorizedRoutingModule
    )
  },
  {
    path: PageRoute.OFFLINE,
    loadChildren: () => import('./shared/pages/offline-internet/offline-internet-routing.module').then(
      (m) => m.OfflineInternetRoutingModule
    )
  },
  {
    path: '**',
    loadChildren: () => import('./features/coupon-home/coupon-home.module').then(
      (m) => m.CouponHomeModule
    )
  },
  // { path: '', redirectTo: PageRoute.HOME, pathMatch: 'full' },
  // { path: PageRoute.HOME, component: HomeComponent },
  // { path: PageRoute.MY_COUPON, component: MycouponComponent },
  // { path: PageRoute.QR_CODE, component: QrcodeComponent },
  // { path: PageRoute.COUPON_DETAIL, component: CouponDetailComponent },
  // { path: PageRoute.COUPON_LIST, component: CouponlistComponent },
  // { path: PageRoute.SEARCH_COUPON, component: SearchCouponComponent },
  // { path: PageRoute.APPLY_COUPON, component: ApplyCouponComponent },
  // { path: PageRoute.UNDER_MAINTENANCE, component: UnderMaintenanceComponent },
  // { path: PageRoute.NOT_FOUND, component: NotfoundComponent },
  // { path: PageRoute.UNAUTHORIZED, component: UnauthorizedComponent },
  // { path: PageRoute.OFFLINE, component: OfflineInternetComponent },
  // { path: PageRoute.SKELETION, component: SkeletonLoaderComponent },
  // { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
