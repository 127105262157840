<ng-container *transloco="let t">

    <span class="font-light expire-text" [attr.data-testid]="'coupon-date-' + index"
        *ngIf="couponStatus === CouponStatus.REDEEM_CONFIRMED">
        {{ t('coupon_card_redeemed_date', { redeemedDate: convertDateFormat(redeemedDate)}) }}
        <!-- วันที่ใช้ {{convertDateFormat(redeemedDate)}} -->
    </span>

    <span class="font-light expire-text" [attr.data-testid]="'coupon-date-' + index"
        *ngIf="couponStatus === CouponStatus.OUT_OF_QUOTA">
        {{ t('coupon_card_out_of_quota') }}
        <!-- ถูกเก็บหมดแล้ว -->
    </span>

    <span class="font-light expire-text" [attr.data-testid]="'coupon-date-' + index"
        *ngIf="couponStatus === CouponStatus.OUT_OF_USED_QUOTA">
        {{ t('coupon_card_out_of_used_quota') }}
        <!-- ถูกใช้หมดแล้ว -->
    </span>

    <span class="font-light disabled-interactive-text" [attr.data-testid]="'coupon-date-' + index"
        *ngIf="couponStatus === CouponStatus.TEMPO_OUT_OF_QUOTA">
        {{ t('coupon_card_view_condition_to_collect') }}
        <!-- กดเพื่อดูเงื่อนไขการเก็บคูปองในครั้งถัดไป -->
    </span>

    <ng-container *ngIf="couponStatus === CouponStatus.NEW">
        <ng-container *ngIf="periodStatus === PeriodStatus.ALLOWED">
            <span class="font-light expire-text" [attr.data-testid]="'coupon-date-' + index" [id]="'showDate' + index">
                {{ t('coupon_card_expire_date', {
                expireDate: convertDateFormat(couponEndDateTime),
                expireTime: convertTimeFormat(couponEndDateTime)
                }) }}
                <!-- หมดอายุ {{ convertDateFormat(couponEndDateTime)
                + ' ' +
                convertTimeFormat(couponEndDateTime) + ' น.' }} -->
            </span>
        </ng-container>

        <ng-container *ngIf="periodStatus === PeriodStatus.PERIOD">
            <span class="font-light disabled-interactive-text" [attr.data-testid]="'coupon-date-' + index"
                [id]="'showDate' + index">
                {{ t('coupon_card_start_collect_time', {
                startCollectTime: convertTimeFormat(couponStartDateTime),
                endCollectTime: convertTimeFormat(couponEndDateTime)
                }) }}
                <!-- เริ่มเก็บได้ {{
                convertTimeFormat(couponStartDateTime)+ '-' +
                convertTimeFormat(couponEndDateTime) + ' น.' }} -->
            </span>
        </ng-container>

        <ng-container *ngIf="periodStatus === PeriodStatus.DATE">
            <span class="font-light disabled-interactive-text" [attr.data-testid]="'coupon-date-' + index"
                [id]="'showDate' + index">
                {{ t('coupon_card_start_collect_date', {
                startCollectDate: convertDateFormat(couponStartDateTime) })
                }}
                <!-- เริ่มเก็บได้ {{
                convertDateFormat(couponStartDateTime) }} -->
            </span>
        </ng-container>

        <ng-container *ngIf="periodStatus === PeriodStatus.START_COLLECT">
            <span class="font-light disabled-interactive-text" [attr.data-testid]="'coupon-date-' + index"
                [id]="'showDate' + index">
                {{ t('coupon_card_first_collect_time', {
                startCollectTime: convertTimeFormat(couponStartDateTime)
                }) }}
                <!-- เริ่มเก็บได้ {{
                convertTimeFormat(couponStartDateTime) + ' น.'
                }} -->
            </span>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="couponStatus === CouponStatus.COLLECTED">
        <ng-container *ngIf="!isApplyCouponQRCodeONCouponList; else messageApplyCouponQRCodeONCouponList">
            <ng-container *ngIf="isInChannel; else notInChannel">
                <ng-container *ngIf="periodStatus === PeriodStatus.ALLOWED">
                    <span class="font-light expire-text" [attr.data-testid]="'coupon-date-' + index"
                        [id]="'showDate' + index">
                        {{ t('coupon_card_expire_date', {
                        expireDate: convertDateFormat(couponEndRedemptionDateTime),
                        expireTime: convertTimeFormat(couponEndRedemptionDateTime)
                        }) }}
                        <!-- หมดอายุ {{
                        convertDateFormat(couponEndRedemptionDateTime)
                        + ' ' +
                        convertTimeFormat(couponEndRedemptionDateTime)
                        + ' น.' }} -->
                    </span>
                </ng-container>
    
                <ng-container *ngIf="periodStatus === PeriodStatus.PERIOD">
                    <span class="font-light disabled-interactive-text" [attr.data-testid]="'coupon-date-' + index"
                        [id]="'showDate' + index">
                        {{ t('coupon_card_start_redeem_time', {
                        startRedeemTime: convertTimeFormat(couponStartRedemptionDatetime),
                        endRedeemTime: convertTimeFormat(couponEndRedemptionDateTime)
                        }) }}
                        <!-- เริ่มใช้ได้ {{
                        convertTimeFormat(couponStartRedemptionDatetime)
                        + '-' +
                        convertTimeFormat(couponEndRedemptionDateTime)
                        + ' น.' }} -->
                    </span>
                </ng-container>
    
                <ng-container *ngIf="periodStatus === PeriodStatus.DATE">
                    <span class="font-light disabled-interactive-text" [attr.data-testid]="'coupon-date-' + index"
                        [id]="'showDate' + index">
                        {{ t('coupon_card_start_redeem_date', {
                        startRedeemDate: convertDateFormat(couponStartRedemptionDatetime) })
                        }}
                        <!-- เริ่มใช้ได้ {{
                        convertDateFormat(couponStartRedemptionDatetime)
                        }} -->
                    </span>
                </ng-container>
    
                <ng-container *ngIf="periodStatus === PeriodStatus.START_REDEEM">
                    <span class="font-light disabled-interactive-text" [attr.data-testid]="'coupon-date-' + index"
                        [id]="'showDate' + index">
                        {{ t('coupon_card_first_redeem_time', {
                        startRedeemTime: convertTimeFormat(couponStartRedemptionDatetime)
                        }) }}
                        <!-- เริ่มใช้ได้ {{
                        convertTimeFormat(couponStartRedemptionDatetime)
                        + ' น.' }} -->
                    </span>
                </ng-container>
            </ng-container>
    
            <ng-template #notInChannel>
                <span class="font-light disabled-interactive-text" [attr.data-testid]="'coupon-date-' + index">
                    {{ t('coupon_card_cannot_use_channel') }}
                    <!-- ไม่สามารถใช้กับช่องทางนี้ได้ -->
                </span>
            </ng-template>

        </ng-container>

        <ng-template #messageApplyCouponQRCodeONCouponList>
            <span class="font-light disabled-interactive-text"
                [attr.data-testid]="'coupon-date-' + index">
                {{ t('coupon_card_use_only_place_payment')}}
            </span>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="couponStatus === CouponStatus.REDEEMED">
        <ng-container
            *ngIf="!isApplyCouponQRCodeONCouponList; else messageApplyCouponQRCodeONCouponList">
            <span class="font-light expire-text"
                [attr.data-testid]="'coupon-date-' + index" [id]="'showDate' + index">
                {{ t('coupon_card_expire_date', {
                expireDate: convertDateFormat(couponEndRedemptionDateTime),
                expireTime: convertTimeFormat(couponEndRedemptionDateTime)
                }) }}
                <!-- หมดอายุ {{
                convertDateFormat(coupon.couponInfo[0].couponEndRedemptionDateTime) + ' ' +
                convertTimeFormat(coupon.couponInfo[0].couponEndRedemptionDateTime) + ' น.'
                }} -->
            </span>
        </ng-container>
        <ng-template #messageApplyCouponQRCodeONCouponList>
            <span class="font-light disabled-interactive-text"
                [attr.data-testid]="'coupon-date-' + index">
                {{ t('coupon_card_use_only_place_payment')}}
            </span>
        </ng-template>
    </ng-container>

    <span class="font-light expire-text"
        *ngIf="couponStatus === CouponStatus.EXPIRED"
        [attr.data-testid]="'coupon-date-' + index" [id]="'showDate' + index">
        {{ t('coupon_card_expire_date', {
        expireDate: convertDateFormat(couponEndRedemptionDateTime),
        expireTime: convertTimeFormat(couponEndRedemptionDateTime)
        }) }}
        <!-- หมดอายุ {{
        convertDateFormat(coupon.couponInfo[0].couponEndRedemptionDateTime) + ' ' +
        convertTimeFormat(coupon.couponInfo[0].couponEndRedemptionDateTime) + ' น.'
        }} -->
    </span>

    <span class="font-light expire-text" [attr.data-testid]="'coupon-date-' + index" [id]="'showDate' + index"
        *ngIf="couponStatus === CouponStatus.NOT_ELIGIBLE_TO_USE">
        {{segmentErrorMessage}}
    </span>

</ng-container>