export const enum Mode {
    CANCEL = "V"
}
export const enum ResponseCode {
    SUCCESS = "0000",
    ERROR_OUT_OF_QUOTA = "1005",
    ERROR_COUPON_EXPIRED = "1006",
    ERROR_NOT_ELIGIBLE = "1010",
    ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND = "1011",
    ERROR_COUPON_NOTFOUND = "1112",
    ERROR_NOT_REGISTERED = "2001",
    ERROR_REGISTERING = "2002",
    ERROR_INVALID_COUPON = "1001",
    ERROR_API = "9999"
}

export const enum ResponseMessage {
    COUPON_NOTFOUND = "coupon not found"
}

export const modalWording = {
    ok: "ตกลง",
    confirm: "ยืนยัน",
    cancel: "ยกเลิก",
    close: "ปิด",
    doItLater: "ไว้ภายหลัง",
    register: "สมัครเป๋าตังเปย์",
    registerOrCheckResults: "สมัคร/ตรวจสอบผล",
    outOfQuota: "สิทธิคงเหลือของคูปองนี้หมดแล้ว",
    thisCouponExpired: "คูปองนี้หมดอายุแล้ว",
    notfoundCoupon: "ไม่พบคูปองนี้",
    chooseAnotherCoupon: "แต่เรายังมีคูปองส่วนลดอื่นๆ <br> ให้คุณได้เลือกใช้อีกมากมาย",
    viewAnotherCoupon: "ดูคูปองอื่นๆ",
    registerPT: "กรุณาสมัครเป๋าตังเปย์<br>เพื่อเข้าใช้บริการคูปอง",
    registerForPickCoupon: "เนื่องจากบริการคูปองจำเป็นต้อง<br>เชื่อมต่อกับเป๋าตังเปย์",
    useCouponAfterRegisterPT: "คูปองนี้สามารถใช้ได้หลังจากที่สมัคร<br>เป๋าตังเปย์แล้วเท่านั้น",
    processingResultCheckAgain: "เป๋าตังเปย์ กำลังอยู่ในระหว่างตรวจสอบผลการสมัคร หรือสมัครไม่สำเร็จ กรุณาตรวจสอบอีกครั้ง",
    checkResultHomePage: "ตรวจสอบผลในหน้าแรก",
    registerPTOrProcessing: "คุณจะสามารถใช้คูปองนี้ได้<br>หลังจากสมัครเป๋าตังเปย์สำเร็จ",
    registerPTOrProcessingDetail: "กรุณาสมัครเป๋าตังเปย์เพื่อใช้คูปองนี้<br>หรือตรวจสอบผลการสมัครได้ที่หน้าแรกเป๋าตัง",
    confirmUseCoupon: "ยืนยันการใช้คูปองนี้",
    afterConfirmCouponHaveTime: "หลังกดยืนยันคูปองนี้จะมีอายุการใช้งาน ",
    minute: " นาที",
    couponExpired: "คูปองหมดอายุแล้ว",
    thankyouForInterest: "ขอบคุณที่ให้ความสนใจ<br>รอติดตามคูปองและโปรโมชันดีๆ ได้อีกมากมาย",
    confirmUsedCoupon: "ยืนยันการใช้คูปองสำเร็จ",
    notAvailableAfterConfirmed: "หลังกดยืนยันคูปองนี้จะไม่สามารถกลับมาใช้ได้อีก",
    outOfService: "ไม่สามารถทำรายการได้ในขณะนี้",
    pleaseTryAgainLater: "กรุณาลองใหม่ในภายหลัง",
    cannotusedScanCoupon: "ไม่",
    confirmUseCouponSuscess: "ยืนยันการใช้คูปองสำเร็จ",
    conditionsUsingCouponNotMet: 'ไม่ตรงเงื่อนไขการใช้คูปอง',
    checkConditionAtCouponDetail: 'คุณสามารถตรวจสอบเงื่อนไข<br>ได้ที่รายละเอียดคูปอง',
    notFoundYourCoupon: "ไม่พบคูปองของคุณ",
    confirmNavigateExternalUrl: "ยืนยันไปยังช่องทางพาร์ทเนอร์?",
    exitFromAppGoToParner: 'คุณจะออกจากแอปฯ นี้ และไปยังช่องทาง<span class="nowrap">พาร์ทเนอร์</span>เพื่อทำรายการต่อ',
    registerPTTitle: "คุณยังไม่มีเป๋าตังเปย์",
    registerPTDescription: "กรุณาสมัครเป๋าตังเปย์เพื่อใช้คูปองนี้<br>หากสมัครแล้ว คุณสามารถกดปุ่มด้านล่าง<br>เพื่อตรวจสอบสถานะ",
    registerPTOrCheckResults: "สมัครเป๋าตังเปย์/ตรวจสอบสถานะ",
    registerCASATitle: "คุณยังไม่มีบัญชีกรุงไทย<br>หรือบัญชีเป๋ามีตัง",
    registerCASADescription: "กรุณาผูกบัญชีกรุงไทย หรือเปิดบัญชีเป๋ามีตัง<br>เพื่อใช้คูปองนี้",
    registerAccount: "ผูก/เปิดบัญชี",
    registerPTOrCASATitle: "คุณยังไม่มีเป๋าตังเปย์<br>หรือบัญชีกรุงไทย",
    registerPTOrCASADescription: "กรุณาเลือกสมัครเป๋าตังเปย์ ผูกบัญชีกรุงไทย<br>หรือเปิดบัญชีเป๋ามีตัง เพื่อใช้คูปองนี้",
}    
