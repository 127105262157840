import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CountdownModule } from 'ngx-countdown';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SafePipe } from './directives/safe/safe.pipe';
import { SwiperModule } from 'swiper/angular';
import { DialogModule } from 'primeng/dialog'
import { FormApplyCouponComponent } from "./components/form-apply-coupon/form-apply-coupon.component";
import { FormSearchCouponComponent } from "./components/form-search-coupon/form-search-coupon.component";
import { QrCodeModule } from 'ng-qrcode';
import { ModalTemplateComponent } from "./components/modal-template/modal-template.component";
import { BottomContentSliderDialogComponent } from "./components/bottom-content-slider-dialog/bottom-content-slider-dialog.component";
import { AnimationLoadingComponent } from "./components/animation-loading/animation-loading.component";
import { SkeletonCardComponent } from "./components/skeleton-loader/skeleton-card/skeleton-card.component";
import { SkeletonDetailComponent } from "./components/skeleton-loader/skeleton-detail/skeleton-detail.component";
import { SkeletonHeaderComponent } from "./components/skeleton-loader/skeleton-header/skeleton-header.component";
import { SkeletonQrCodeComponent } from "./components/skeleton-loader/skeleton-qr-code/skeleton-qr-code.component";
import { FormsModule } from "@angular/forms";
import { TranslocoModule } from "@ngneat/transloco";
import { RouterModule } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { HeaderComponent } from "./components/header/header.component";
import { ToastNotificationComponent } from "./components/toast-notification/toast-notification.component";
import { BadgeLabelComponent } from "./components/badge-label/badge-label.component";
import { CouponCardImageComponent } from "./components/coupon-card-image/coupon-card-image.component";
import { CouponCardProgressComponent } from "./components/coupon-card-progress/coupon-card-progress.component";
import { CouponCardBottomDescriptionComponent } from "./components/coupon-card-bottom-description/coupon-card-bottom-description.component";
import { SkeletonPageComponent } from "./components/skeleton-loader/skeleton-page/skeleton-page.component";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { CouponCardButtonComponent } from "./components/coupon-card-button/coupon-card-button.component";
import { registerLocaleData } from "@angular/common";
import localeTH from '@angular/common/locales/th';
registerLocaleData(localeTH);

const modules = [
    CommonModule,
    NgxSkeletonLoaderModule,
    QrCodeModule,
    FormsModule,
    RouterModule,
    MatDialogModule,
    FontAwesomeModule,
    DialogModule,
    OverlayPanelModule,
    CountdownModule,
    SwiperModule,
    TranslocoModule,
]

const components = [
    HeaderComponent,
    FormSearchCouponComponent,
    FormApplyCouponComponent,
    ModalTemplateComponent,
    BottomContentSliderDialogComponent,
    AnimationLoadingComponent,
    SkeletonPageComponent,
    SkeletonCardComponent,
    SkeletonDetailComponent,
    SkeletonHeaderComponent,
    SkeletonQrCodeComponent,
    ToastNotificationComponent,
    BadgeLabelComponent,
    CouponCardImageComponent,
    CouponCardProgressComponent,
    CouponCardBottomDescriptionComponent,
    CouponCardButtonComponent,
    SafePipe,

];
@NgModule({
    declarations: components,
    imports: modules,
    exports: [...components, ...modules],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }