import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    translocoConfig,
    TranslocoLoader,
} from '@ngneat/transloco';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) { }

    getTranslation(lang: string) {
        const language = import(`../../../assets/i18n/${lang}.json`);
        return language;
    }
}

export function languagePackFactory() {
    let defaultLang = 'th-TH';
    const availableLangs = ['en-TH', 'th-TH'];

    return translocoConfig({
        availableLangs,
        defaultLang,
        fallbackLang: ['en-TH', 'th-TH'],
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.isProduction,
    });
}