import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Subject } from 'rxjs';
import { catchError, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as Lodash from "lodash";
import { PageRoute } from '@core/routers/route-config';
import { SelectedCoupon } from '@core/models/core-state.model';
import { AppState, JWTSession } from './root-store.model';
import { RootStoreService } from './root-store.service';
import * as RootStoreActions from './root-actions';
import * as CoreCouponActions from '@core/store/core.action';
import * as SearchCouponActions from '@features/search-coupon/store/search-coupon.action';
import * as ApplyCouponActions from '@features/apply-coupon/store/apply-coupon.action';
import { SessionService } from '@utils/session.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class RootStoreEffects {
  private _destroy = new Subject<void>();
  isDestroy = false;
  triggerDestroyRootEffect() {
    if (this.isDestroy) return;

    this.isDestroy = true;
    this._destroy.next();
    this._destroy.complete();
  }

  private manageStepRouteConfigFromStorage() {
    const stepRouteConfig: string[] = localStorage.getItem("stepRouteConfig") ? JSON.parse(localStorage.getItem("stepRouteConfig") ?? "") : [];

    if (Lodash.isEmpty(stepRouteConfig))
      this.store.dispatch(CoreCouponActions.clearStepRouteConfig())
    else
      this.store.dispatch(CoreCouponActions.saveStepRouteConfig({ routesUrl: stepRouteConfig }));
  }

  initialization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RootStoreActions.initialization),
      mergeMap(() =>
        this.activatedRoute.queryParams.pipe(
          filter((v) => {
            return ((v['authorizationCode'] && v['webConfig']) || v['code'] || sessionStorage.getItem('codeToken'));
          }),
          takeUntil(this._destroy),
          tap((data) => {

            this.translocoService.setActiveLang('th-TH');
            /* New Flow Authentication */
            if (data['webConfig'] && data['authorizationCode']) {
              if (data['channelId']) {
                this.store.dispatch(RootStoreActions.saveCouponChannel({ couponChannel: data['channelId'] }));
              }
              sessionStorage.removeItem('accessToken');
              this.store.dispatch(RootStoreActions.saveAuthorizationCode({ authorizationCode: data['authorizationCode'] }));
              this.store.dispatch(RootStoreActions.saveAccessToken({ accessToken: "" }));
              this.store.dispatch(RootStoreActions.postAuthVerify({ authorizationCode: data['authorizationCode'], webConfig: data['webConfig'] }))

              if (localStorage.getItem("navigateExternalPage")) {
                localStorage.setItem("navigateExternalPageTemp", localStorage.getItem("navigateExternalPage")!)
                if (data['couponId'] && (data['couponId'] !== localStorage.getItem("navigateExternalPage"))) {
                  localStorage.removeItem("stepRouteConfig");
                  localStorage.removeItem("selectedCoupon");
                } else {
                  if (this.location.path().includes(PageRoute.PATH_COUPON_DETAIL)) {
                    sessionStorage.setItem("checkCouponDetailStatus", "true");
                  }

                  if (!data['couponId'] && (
                    this.location.path().includes(PageRoute.PATH_HOME) ||
                    this.location.path().includes(PageRoute.PATH_MY_COUPON)
                  )) {
                    localStorage.removeItem("stepRouteConfig");
                    localStorage.removeItem("selectedCoupon");
                    localStorage.removeItem("amount");
                    localStorage.removeItem("onCheckedTab");
                    localStorage.removeItem("selectedCategory");
                    localStorage.removeItem("searchKeyword");
                    localStorage.removeItem("applyCouponKeyword");
                    localStorage.removeItem("navigateExternalPageTemp");
                  }
                }
                localStorage.removeItem("navigateExternalPage");
              } else {
                localStorage.removeItem("amount");
                localStorage.removeItem("stepRouteConfig");
                localStorage.removeItem("onCheckedTab");
                localStorage.removeItem("selectedCoupon");
                localStorage.removeItem("selectedCategory");
                localStorage.removeItem("searchKeyword");
                localStorage.removeItem("applyCouponKeyword");
                localStorage.removeItem("navigateExternalPageTemp");
              }

              this.manageStepRouteConfigFromStorage();
              return;
            }
            /* New Flow Authentication */

            /* Existing Flow Authentication */
            if (data['code']) {
              sessionStorage.setItem('codeToken', data['code']);
              sessionStorage.removeItem('accessToken');
              this.store.dispatch(RootStoreActions.saveAccessToken({ accessToken: "" }));
              if (localStorage.getItem("navigateExternalPage")) {
                localStorage.setItem("navigateExternalPageTemp", localStorage.getItem("navigateExternalPage")!)
                if (data['couponId'] && (data['couponId'] !== localStorage.getItem("navigateExternalPage"))) {
                  localStorage.removeItem("stepRouteConfig");
                  localStorage.removeItem("selectedCoupon");
                } else {
                  if (this.location.path().includes(PageRoute.PATH_COUPON_DETAIL)) {
                    sessionStorage.setItem("checkCouponDetailStatus", "true");
                  }

                  if (!data['couponId'] && (
                    this.location.path().includes(PageRoute.PATH_HOME) ||
                    this.location.path().includes(PageRoute.PATH_MY_COUPON)
                  )) {
                    localStorage.removeItem("stepRouteConfig");
                    localStorage.removeItem("selectedCoupon");
                    localStorage.removeItem("amount");
                    localStorage.removeItem("onCheckedTab");
                    localStorage.removeItem("selectedCategory");
                    localStorage.removeItem("searchKeyword");
                    localStorage.removeItem("applyCouponKeyword");
                    localStorage.removeItem("navigateExternalPageTemp");
                  }
                }
                localStorage.removeItem("navigateExternalPage");
              } else {
                localStorage.removeItem("amount");
                localStorage.removeItem("stepRouteConfig");
                localStorage.removeItem("onCheckedTab");
                localStorage.removeItem("selectedCoupon");
                localStorage.removeItem("selectedCategory");
                localStorage.removeItem("searchKeyword");
                localStorage.removeItem("applyCouponKeyword");
                localStorage.removeItem("navigateExternalPageTemp");
              }
            }

            this.manageStepRouteConfigFromStorage();
            const webToken: string = sessionStorage.getItem('codeToken')!;

            if (webToken) {

              const parseWebToken = this.sessionService.parseJwt(JSON.stringify(webToken));

              const jwtSession: JWTSession = {
                couponChannel: parseWebToken.channelId,
                withHeader: parseWebToken.withHeader,
                status: parseWebToken.status,
                registerCallbackUrl: parseWebToken.registerCallbackUrl ?? "",
                processingCallbackUrl: parseWebToken.processingCallbackUrl ?? "",
                paymentCallbackUrl: parseWebToken.paymentCallbackUrl ?? "",
                chooseCouponCallbackUrl: parseWebToken.chooseCouponCallbackUrl ?? "",
                callbackUrl: parseWebToken.callbackUrl ?? "",
                mode: parseWebToken.mode ?? "",
                webTokenId: parseWebToken.webTokenId ?? "",
                sourceOfFund: parseWebToken.sourceOfFund ?? "",
                callbackDestination: parseWebToken.callbackDestination ?? "",
                entryPoint: parseWebToken.entryPoint ?? "",
                paymentMethod: parseWebToken.paymentMethod ?? "",
                userSOF: parseWebToken.userSOF ?? []
              };

              this.store.dispatch(
                RootStoreActions.saveWebToken({ webToken, jwtSession })
              );

              const accessToken = sessionStorage.getItem('accessToken');
              if (accessToken) {
                sessionStorage.setItem('accessToken', accessToken);
                this.store.dispatch(RootStoreActions.saveAccessToken({ accessToken: accessToken }));
              }
            }

            /* Existing Flow Authentication */
          }),
          map(({ couponId, couponCode, hasCountdownTimer, countdownTimeInSeconds, order, searchKeyword, applyKeyword, category }) => {
            if (Lodash.isEmpty(couponId)) return;

            /* Open Use for Next phase */
            const selectedCoupon: SelectedCoupon = {
              couponId: couponId,
              couponCode: couponCode ?? '',
              hasCountdownTimer: hasCountdownTimer ?? false,
              countdownTimeInSeconds: countdownTimeInSeconds ?? 0,
              order: order ? Number(order) : 0
            }

            this.store.dispatch(CoreCouponActions.saveSelectedCoupon({ selectedCoupon }));

            // Support PT New version
            if (category) {
              this.store.dispatch(CoreCouponActions.saveSelectedCategoryId({ selectedCategoryId: category }));
            }

            if (searchKeyword) {
              const keyword = this.decodeValidURIComponent(searchKeyword);
              this.store.dispatch(SearchCouponActions.saveSeachKeyword({ keyword: keyword }));
            }

            if (applyKeyword) {
              this.store.dispatch(CoreCouponActions.saveStepRouteConfig({ routesUrl: [PageRoute.PATH_APPLY_COUPON] }));
              const keyword = this.decodeValidURIComponent(applyKeyword);
              this.store.dispatch(ApplyCouponActions.saveApplyCouponKeyword({ keyword: keyword }));
            }
            /* Close Use for Next phase */
          })
        )
      )
    );
  }, { dispatch: false });

  postAuthVerify$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RootStoreActions.postAuthVerify),
      mergeMap((action) =>
        this.rootStoreService.postAuthVerify(
          action.authorizationCode, action.webConfig
        ).pipe(
          tap((response) => {
            this.triggerDestroyRootEffect();
            if (response.data.accessToken) {
              this.store.dispatch(RootStoreActions.saveAccessToken({ accessToken: response.data.accessToken }))
              const parseJTWToken = this.sessionService.parseJwt(JSON.stringify(response.data.accessToken));
              const jwtSession = this.praseJWTWebCore(parseJTWToken.webConfig);
              this.store.dispatch(RootStoreActions.saveJWTSession({ jwtSession }));
            }
          }),
          catchError((err: HttpErrorResponse) => {
            sessionStorage.removeItem('codeToken');
            sessionStorage.removeItem('accessToken');
            sessionStorage.removeItem('channelId');
            if ((err?.error?.result?.code == 8000) || (err.status == 403) || (err.status == 503) || (err.status == 0 && err.statusText == "Unknown Error")) {
              this.router.navigateByUrl(PageRoute.PATH_UNDER_MAINTENANCE);
            } else {
              this.router.navigateByUrl(PageRoute.PATH_UNAUTHORIZED);
            }
            return EMPTY;
          })
        )
      )
    );
  }, { dispatch: false }
  );

  praseJWTWebCore(parseWebToken: any) {
    const jwtSession: JWTSession = {
      couponChannel: parseWebToken.channelId,
      withHeader: true,
      status: parseWebToken.status,
      registerCallbackUrl: parseWebToken.registerCallbackUrl ?? "",
      processingCallbackUrl: parseWebToken.processingCallbackUrl ?? "",
      paymentCallbackUrl: parseWebToken.paymentCallbackUrl ?? "",
      chooseCouponCallbackUrl: parseWebToken.chooseCouponCallbackUrl ?? "",
      callbackUrl: parseWebToken.callbackUrl ?? "",
      mode: parseWebToken.mode ?? "",
      webTokenId: parseWebToken.webTokenId ?? "",
      sourceOfFund: parseWebToken.sourceOfFund ?? "",
      callbackDestination: parseWebToken.callbackDestination ?? "",
      entryPoint: parseWebToken.entryPoint ?? "",
      paymentMethod: parseWebToken.paymentMethod ?? "",
      userSOF: parseWebToken.userSOF ?? []
    };
    return jwtSession;
  }

  decodeValidURIComponent(keyword: string) {
    try {
      const decodeKeyword = decodeURIComponent(keyword);
      return decodeKeyword;
    } catch (e) {
      return keyword;
    }
  }

  constructor(
    private actions$: Actions,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private rootStoreService: RootStoreService,
    private sessionService: SessionService,
    private router: Router,
    private translocoService: TranslocoService,
    private location: Location

  ) { }
}
