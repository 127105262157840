import { Component, Input, OnInit } from '@angular/core';
import { CouponStatus, DeductionType } from '@core/models/core-enum.model';

@Component({
  selector: 'mp-coupon-card-progress',
  templateUrl: './coupon-card-progress.component.html',
  styleUrls: ['./coupon-card-progress.component.scss']
})
export class CouponCardProgressComponent implements OnInit {
  @Input() deductionType: string;
  @Input() couponStatus: string;
  @Input() progressionPercent: number;
  CouponStatus = CouponStatus;
  DeductionType = DeductionType;
  constructor() { }

  ngOnInit(): void {
  }

}
