<div [class]="'header flex justify-center items-center h-auto px-4 text-center header-height'">
  <div class="has-text-left back-btn">
    <a class="link-item" data-testid="link-back-icon">
      <img src="../../../../../assets/icon/ic_chevron-left-2.png" alt="ic_search" width="24" height="24"
        class="ic-color">
    </a>
  </div>
  <div class="item header-title" *ngIf="path && path !== PageRoute.QR_CODE else qrCodePath">
    <!-- home -->
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
            'width': '120px',
            'height': '24px',
            'margin-top': '6px',
            'background': '#85F5F5',
            'border-radius': '8px',
            'margin-bottom': '0px'
          }">
    </ngx-skeleton-loader>
  </div>

  <ng-template #qrCodePath>
    <div class="header-title"></div>
  </ng-template>

  <div class="header-temp-last">
  </div>
</div>