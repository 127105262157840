import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResponseCode } from '@core/configs/response-code-enum';
import { CouponEventTagAction } from '@core/ga-tagging/coupon-ga.model';
import { CouponGAService } from '@core/ga-tagging/coupon-ga.service';
import { AnalyticAction, AnalyticCategory, AnalyticFirebaseParam, InterfaceToNativeCommand } from '@core/js-bridge/webview-to-native-interface-enum.model';
import { FirebaseParam } from '@core/js-bridge/webview-to-native-interface.model';
import { WebviewToNativeInterfaceService } from '@core/js-bridge/webview-to-native-interface.service';
import { CouponChannel } from '@core/models/core-enum.model';
import { SelectedCoupon } from '@core/models/core-state.model';
import { RootStoreService } from '@root-store/root-store.service';
import { ButtonModal, IModalTemplate } from '@shared/models/modal.model';

@Component({
  selector: 'app-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss']
})
export class ModalTemplateComponent implements OnInit {
  @Output() onActionReturn: EventEmitter<any> = new EventEmitter<any>();
  couponChannel = this.rootStoreService.jwtSession!.couponChannel;
  callbackDestination = this.rootStoreService.jwtSession!.callbackDestination;
  selectedCoupon: SelectedCoupon | undefined;
  CouponChannel = CouponChannel;
  modalObj: IModalTemplate = {
    title: "",
    detail: "",
    analyticModal: {
      eventCategory: '',
      firebaseParam: undefined,
    },
    button: [],
    isFullWidthBtn: false,
    deeplinkCallbackUrl: undefined,
    templateName: ''
  };

  isMultiButton: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ModalTemplateComponent>,
    private rootStoreService: RootStoreService,
    private couponGAService: CouponGAService,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService,
  ) { }

  ngOnInit(): void {
    this.openModal();
  }

  openModal() {
    this.modalObj.title = this.data.title;
    this.modalObj.detail = this.data.detail;
    this.modalObj.analyticModal = this.data.analyticModal;
    this.modalObj.button = this.data.button;
    this.modalObj.isFullWidthBtn = this.data.isFullWidthBtn || false;
    this.modalObj.deeplinkCallbackUrl = this.data.deeplinkCallbackUrl ?? undefined;
    this.modalObj.templateName = this.data.templateName ?? undefined;

    if (this.data.button.length > 1) {
      this.setGATag(
        `${this.data.title}//${this.data.detail}`,
        this.couponChannel === CouponChannel.NEXT ? AnalyticAction.VIEW : AnalyticAction.POPUP
      );
      this.isMultiButton = true;
    } else {
      this.setGATag(`
        ${this.data.title}//${this.data.detail}`,
        this.couponChannel === CouponChannel.NEXT ? AnalyticAction.VIEW : AnalyticAction.ERROR
      );
      this.isMultiButton = false;
    }

    if (this.couponChannel === CouponChannel.NEXT && this.modalObj.analyticModal.eventCategory === AnalyticCategory.COUPON_API_ERROR && this.modalObj.analyticModal.firebaseParam!.length > 3) {
      const firebaseParamError = this.modalObj.analyticModal.firebaseParam! ?? [];
      const code = this.modalObj.analyticModal.firebaseParam![1] ?? ResponseCode.ERROR_API;
      const message = this.modalObj.analyticModal.firebaseParam![3] ?? this.modalObj.analyticModal.eventCategory;

      this.webviewToNativeInterfaceService.trackAction(
        {
          action: AnalyticAction.ERROR,
          category: this.modalObj.analyticModal.eventCategory ?? '',
          label: `${code.value}: ${message.value}`,
        },
        firebaseParamError
      );
    }
  }

  closeModal(val: ButtonModal, isSetGATag: boolean = true) {
    this.dialogRef.close(val.action);

    if (isSetGATag) {
      this.setGATag(val.eventLabel);
    }
  }

  clickAction(val: ButtonModal) {
    this.closeModal(val, false);
    this.onActionReturn.emit(val.action);
    this.setGATag(val.eventLabel);
  }

  navigateBackWithJsbridgeOnNext() {
    this.webviewToNativeInterfaceService.interfaceToNative(
      {
        command: InterfaceToNativeCommand.COMMAND_ROUTING,
        content: {
          destination: this.callbackDestination,
        }
      }
    );
  }

  setGATag(eventLabel: string, eventAction?: string) {
    const gaObj: CouponEventTagAction = {
      eventName: this.modalObj.analyticModal.eventCategory ?? '',
      eventCategory: this.modalObj.analyticModal.eventCategory ?? '',
      eventLabel: `${this.modalObj.analyticModal.eventCategory}_${eventLabel}`,
      eventAction: eventAction ?? AnalyticAction.CLICK,
      campaignCode: this.modalObj.analyticModal.firebaseParam?.find(k => k.key === AnalyticFirebaseParam.CAMPAIGN_ID)?.value ?? '',
      couponChannel: this.couponChannel ?? ''
    }
    this.couponGAService.trackActionGATag(gaObj)

    let firebaseParam: FirebaseParam[] = [];

    if (this.couponChannel === CouponChannel.NEXT) {
      if (this.modalObj.analyticModal.eventCategory === AnalyticCategory.COUPON_API_ERROR) {
        firebaseParam = [];
      } else {
        if (this.modalObj.analyticModal.firebaseParam!.length > 1) {
          firebaseParam = eventAction ? [this.modalObj.analyticModal.firebaseParam![0]] : [this.modalObj.analyticModal.firebaseParam![1]]
        } else {
          if (eventAction) {
            firebaseParam = [this.modalObj.analyticModal.firebaseParam![0]]
          } else {
            firebaseParam = this.modalObj.analyticModal.firebaseParam!.length > 0 ? [this.modalObj.analyticModal.firebaseParam![0]] : []
          }

        }
      }
    } else {
      firebaseParam = this.modalObj.analyticModal.firebaseParam ?? []
    }

    if (eventAction === AnalyticAction.VIEW && this.couponChannel === CouponChannel.NEXT) {
      this.webviewToNativeInterfaceService.interfaceToNativeTrackActionView(
        {
          action: eventAction,
          category: this.modalObj.analyticModal.eventCategory ?? '',
          label: this.modalObj.analyticModal.eventCategory ?? '',
        },
        firebaseParam
      );
    } else {
      this.webviewToNativeInterfaceService.trackAction(
        {
          action: eventAction ?? AnalyticAction.CLICK,
          category: this.modalObj.analyticModal.eventCategory ?? '',
          label: `${this.modalObj.analyticModal.eventCategory}_${eventLabel}`,
          firebase_screen: this.modalObj.analyticModal.eventCategory ?? '',
        },
        firebaseParam
      );
    }
  }
}
