export enum CouponHeaderTemplate {
    COUPON_HOME = 'coupon-home',
    MY_COUPON = 'my-coupon',
    COUPON_LIST = 'coupon-list',
    SEARCH_COUPON = 'search-coupon',
    APPLY_COUPON = 'apply-coupon',
    QR_CODE = 'qr-code',
    COUPON_DETAIL = 'coupon-detail',

}
export enum CouponCardTemplate {
    COUPON_HOME = 'coupon-home',
    MY_COUPON = 'my-coupon',
    COUPON_LIST = 'coupon-list',
    SEARCH_COUPON = 'search-coupon',
    APPLY_COUPON = 'apply-coupon',
}

export enum CouponCardDialogTemplate {
    COUPON_HOME_BOTTOM_DIALOG = 'coupon-home-bottom-dialog',
    MY_COUPON_BOTTOM_DIALOG = 'my-coupon-bottom-dialog',
    COUPON_LIST_BOTTOM_DIALOG = 'coupon-list-bottom-dialog',
    SEARCH_COUPON_BOTTOM_DIALOG = 'search-coupon-bottom-dialog',
    APPLY_COUPON_BOTTOM_DIALOG = 'apply-coupon-bottom-dialog'

}