import { createReducer, on } from '@ngrx/store';
import * as RootStoreActions from './root-actions';
import * as RootStoreType from './root-store.model';

export const initialState: RootStoreType.Session = {
  isInitialised: false,
  authorizationCode: '',
  webToken: '',
  accessToken: '',
  jwtSession: {
    couponChannel: '',
    status: '',
    callbackUrl: '',
    registerCallbackUrl: '',
    processingCallbackUrl: '',
    paymentCallbackUrl: '',
    chooseCouponCallbackUrl: '',
    withHeader: undefined,
    mode: '',
    webTokenId: '',
    sourceOfFund: '',
    callbackDestination: '',
    entryPoint: '',
    paymentMethod: '',
    userSOF: []
  }
};

export const sessionReducer = createReducer<RootStoreType.AppState['session']>(
  initialState,
  on(RootStoreActions.saveAuthorizationCode, (state, action) => {
    return {
      ...state,
      authorizationCode: action.authorizationCode
    }
  }),
  on(RootStoreActions.saveAccessToken, (state, action) => {
    return {
      ...state,
      accessToken: action.accessToken
    }
  }),
  on(RootStoreActions.saveWebToken, (state, action) => {
    return {
      ...state,
      isInitialised: true,
      webToken: action.webToken,
      jwtSession: {
        couponChannel: action.jwtSession.couponChannel,
        status: action.jwtSession.status,
        callbackUrl: action.jwtSession.callbackUrl,
        registerCallbackUrl: action.jwtSession.registerCallbackUrl,
        processingCallbackUrl: action.jwtSession.processingCallbackUrl,
        paymentCallbackUrl: action.jwtSession.paymentCallbackUrl,
        chooseCouponCallbackUrl: action.jwtSession.chooseCouponCallbackUrl,
        withHeader: action.jwtSession.withHeader,
        mode: action.jwtSession.mode,
        webTokenId: action.jwtSession.webTokenId,
        sourceOfFund: action.jwtSession.sourceOfFund,
        callbackDestination: action.jwtSession.callbackDestination,
        entryPoint: action.jwtSession.entryPoint,
        paymentMethod: action.jwtSession.paymentMethod,
        userSOF: action.jwtSession.userSOF
      }
    };
  }),
  on(RootStoreActions.saveJWTSession, (state, action) => {
    return {
      ...state,
      isInitialised: true,
      jwtSession: {
        couponChannel: action.jwtSession.couponChannel,
        status: action.jwtSession.status,
        callbackUrl: action.jwtSession.callbackUrl,
        registerCallbackUrl: action.jwtSession.registerCallbackUrl,
        processingCallbackUrl: action.jwtSession.processingCallbackUrl,
        paymentCallbackUrl: action.jwtSession.paymentCallbackUrl,
        chooseCouponCallbackUrl: action.jwtSession.chooseCouponCallbackUrl,
        withHeader: action.jwtSession.withHeader,
        mode: action.jwtSession.mode,
        webTokenId: action.jwtSession.webTokenId,
        sourceOfFund: action.jwtSession.sourceOfFund,
        callbackDestination: action.jwtSession.callbackDestination,
        entryPoint: action.jwtSession.entryPoint,
        paymentMethod: action.jwtSession.paymentMethod,
        userSOF: action.jwtSession.userSOF
      }
    };
  }),
  on(RootStoreActions.saveCouponChannel, (state, action) => {
    return {
      ...state,
      jwtSession: {
        ...state.jwtSession,
        couponChannel: action.couponChannel,
      }
    };
  })
);
