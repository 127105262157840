import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { AppState } from "../root-store/root-store.model";
import * as CoreCouponActions from "./store/core.action";
import * as CoreCouponSelectors from "./store/core.selector";
import { SelectedCoupon } from "./models/core-state.model";
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class CoreCouponFacade {
    constructor(
        private store: Store<AppState>,
    ) { }

    /* Core */
    couponId$ = this.store.select(CoreCouponSelectors.selectedCouponId);
    selectedCategoryId$ = this.store.select(CoreCouponSelectors.selectCategoryId);
    selectedCoupon$ = this.store.select(CoreCouponSelectors.selectedCoupon);
    currentPage$ = this.store.select(CoreCouponSelectors.selectCurrentPage);
    stepRouteConfig$ = this.store.select(CoreCouponSelectors.selectStepRouteConfig);
    /* End Core */

    collectCoupon$ = this.store.select(CoreCouponSelectors.selectCollectCoupon);
    couponCollectedData$ = new Subject<any>();
    isLoadingCollectCoupon$ = new Subject<boolean>();
    isLoadingCollectedSccuess$ = new Subject<any>();

    reserveCouponOrder$ = this.store.select(CoreCouponSelectors.selectReserveCouponOrder);
    permissionUseCoupon$ = this.store.select(CoreCouponSelectors.selectPermissionUseCoupon);

    loadFetchCollectCoupon(couponId: string, couponOrder?: number) {
        this.store.dispatch(CoreCouponActions.loadCollectCoupon({ couponId: couponId, couponOrder: couponOrder }));
    }

    clearCollectCoupon() {
        this.store.dispatch(CoreCouponActions.clearCollectCoupon());
    }

    loadReserveCouponOrder(couponId: string, couponOrder: number, mode?: string) {
        this.store.dispatch(CoreCouponActions.loadReserveCouponOrder({ couponId: couponId, couponOrder: couponOrder, mode: mode }));
    }

    clearReserveCouponOrder() {
        this.store.dispatch(CoreCouponActions.clearReserveCouponOrder());
    }

    loadPermissionUseCoupon(couponId: string, couponOrder: number, checkStatus: boolean = false) {
        this.store.dispatch(CoreCouponActions.loadPermissionUseCoupon({ couponId: couponId, couponOrder: couponOrder, checkStatus: checkStatus }));
    }

    clearPermissionUseCoupon() {
        this.store.dispatch(CoreCouponActions.clearPermissionUseCoupon());
    }

    saveCurrentPage(currentPage: string) {
        this.store.dispatch(CoreCouponActions.saveCurrentPage({ currentPage: currentPage }));
    }

    saveSelectedCouponValue(selectedCoupon: SelectedCoupon) {
        this.store.dispatch(CoreCouponActions.saveSelectedCoupon({ selectedCoupon: selectedCoupon }));
    }

    clearSelectedCouponValue() {
        this.store.dispatch(CoreCouponActions.clearSelectedCoupon());
    }

    saveStepRouteConfig(routesUrl: string[]) {
        this.store.dispatch(CoreCouponActions.saveStepRouteConfig({ routesUrl: routesUrl }));
    }

    removeStepRouteConfig(routeUrl: string) {
        this.store.dispatch(CoreCouponActions.removeStepRouteConfig({ routeUrl: routeUrl }));
    }

    clearStepRouteConfig() {
        this.store.dispatch(CoreCouponActions.clearStepRouteConfig());
    }

    saveSelectedCategoryId(selectCategoryId: string) {
        this.store.dispatch(CoreCouponActions.saveSelectedCategoryId({ selectedCategoryId: selectCategoryId }))
    }
}