<ng-container [ngSwitch]="couponTemplate">
    <ng-container *ngSwitchCase="CouponCardTemplate.COUPON_HOME">
        <ng-container *ngTemplateOutlet="couponHomeTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="CouponCardTemplate.SEARCH_COUPON">
        <ng-container *ngTemplateOutlet="searchCouponTemplate"></ng-container>
    </ng-container>
</ng-container>


<ng-template #couponHomeTemplate>
    <div class="search-input" (click)="goSearch()" [attr.data-testid]="'home-search-coupon'">
        <img src="../../../../assets/images/ic_search.png" alt="ic_search" width="24" height="24">
        <input [attr.data-testid]="'search-coupon-form'" type="text" [placeholder]="currentPlaceholder.trim()" readonly>
    </div>
</ng-template>


<ng-template #searchCouponTemplate>
    <div class="search-input search-width">
        <img src="../../../../assets/images/ic_search.png" alt="ic_search" width="24" height="24">
        <input #inputSearch type="text" enterkeyhint="search" [attr.data-testid]="'search-coupon-form'"
            [(ngModel)]="keyword" [placeholder]="currentPlaceholder.trim()" [maxlength]="60"
            (ngModelChange)="onSearchChange()" (keydown.enter)="searchCouponAction()"
            (keydown.shift.enter)="searchCouponAction()">
        <img class="pointer" src="../../../../assets/images/ic_close-circle.png" alt="ic_close-circle" width="24"
            height="24" *ngIf="keyword.length > 0" (click)="clearKeyword()" [attr.data-testid]="'clear-keyword-button'">
    </div>
</ng-template>