import { Injectable } from "@angular/core";
import { EventFunctionList, EventAction, FirebaseParam, FirebaseScreen, EventActionWithNative, EventFunctionToNative } from "./webview-to-native-interface.model";
import { CouponChannel } from "../models/core-enum.model";
import { Subject } from "rxjs";
import { Store } from '@ngrx/store';
import { AppState } from "../../root-store/root-store.model";
import * as RootSelectors from '../../root-store/root-selectors';

declare const webviewToNativeInterface: EventFunctionList;
declare const nativeInterface: EventFunctionToNative;
declare const window: any;
@Injectable({
  providedIn: 'root',
})
export class WebviewToNativeInterfaceService {
  jwtSession$ = this.store.select(RootSelectors.selectJWTSession);
  couponChannel = "";
  constructor(
    private store: Store<AppState>
  ) {
    this.jwtSession$.subscribe((jwtSession) => {
      this.couponChannel = jwtSession.couponChannel;
    });
  }

  private mappingCustomDimension(firebaseParams?: FirebaseParam[]) {
    const customDimension = firebaseParams?.map((data => {
      return {
        key: data.index?.toString(),
        value: data.value?.toString()
      }
    }))
    return customDimension;
  }

  private mappingfirebaseParams(firebaseParams?: FirebaseParam[]) {
    const firebaseParam = firebaseParams?.map((data => {
      return {
        key: data.key?.toString(),
        value: data.value?.toString()
      }
    }))
    return firebaseParam;
  }

  trackAction(event: EventAction, firebaseParams?: FirebaseParam[]) {
    if (this.couponChannel === CouponChannel.NEXT) {
      const customDimensions = firebaseParams ? this.mappingCustomDimension(firebaseParams) : [];
      const firebaseParam = firebaseParams ? this.mappingfirebaseParams(firebaseParams) : [];
      const data = firebaseParams ?
        {
          ga: {
            trackGA: true,
            event,
            firebaseParam,
            customDimensions
          }
        }
        : {
          ga: {
            trackGA: true,
            event
          }
        };

      const body = JSON.stringify(data);
      const ua = navigator.userAgent.toLowerCase();
      const isiOS = /ipad|iphone|ipod|macintosh|macintel/.test(ua);

      try {
        if (isiOS) {
          window.webkit.messageHandlers.nativeInterface.postMessage(body);
        } else {
          nativeInterface.onRetrievePayloadString(body);
        }
      } catch (error) {
        // uncomment for GA testing purpose
        console.log(body);
        // handle when open on web
      }
    } else {
      const data = firebaseParams
        ? {
          event,
          firebaseParams,
        }
        : { event };

      const body = JSON.stringify(data);
      const ua = navigator.userAgent.toLowerCase();
      const isiOS = /ipad|iphone|ipod|macintosh|macintel/.test(ua);

      try {
        if (isiOS) {
          window.webkit.messageHandlers.webviewToNativeInterface.postMessage(body);
        } else {
          webviewToNativeInterface.gaTagging(body);
        }
      } catch (error) {
        // uncomment for GA testing purpose
        // console.log(body);
        // handle when open on web
      }
    }
  }

  interfaceToNativeTrackActionView(event: any, firebaseParams?: FirebaseParam[]) {
    if (this.couponChannel === CouponChannel.NEXT) {
      const customDimensions = firebaseParams ? this.mappingCustomDimension(firebaseParams) : [];
      const firebaseParam = firebaseParams ? this.mappingfirebaseParams(firebaseParams) : [];
      const data = firebaseParams ?
        {
          ga: {
            event,
            firebaseParam,
            customDimensions
          }
        }
        : {
          ga: {
            event
          }
        };
      const body = JSON.stringify(data);
      const ua = navigator.userAgent.toLowerCase();
      const isiOS = /ipad|iphone|ipod|macintosh|macintel/.test(ua);
      try {
        if (isiOS) {
          window.webkit.messageHandlers.nativeInterface.postMessage(body);
        } else {
          nativeInterface.onRetrievePayloadString(body);
        }
      } catch (error) {
        console.log("Sreen view : ", body);
      }
    } else {
      const data = { event };
      const body = JSON.stringify(data);
      const ua = navigator.userAgent.toLowerCase();
      const isiOS = /ipad|iphone|ipod|macintosh|macintel/.test(ua);
      try {
        if (isiOS) {
          window.webkit.messageHandlers.webviewToNativeInterface.postMessage(body);
        } else {
          webviewToNativeInterface.gaTagging(body);
        }
      } catch (error) { }
    }
  }

  trackPageView(event: FirebaseScreen) {
    const data = { event }
    const body = JSON.stringify(data);
    const ua = navigator.userAgent.toLowerCase();
    const isiOS = /ipad|iphone|ipod|macintosh|macintel/.test(ua);

    try {
      if (isiOS) {
        window.webkit.messageHandlers.webviewToNativeInterface.postMessage(body);
      } else {
        webviewToNativeInterface.gaTagging(body);
      }
    } catch (error) {
      // uncomment for GA testing purpose
      // console.log(body);
      // handle when open on web
    }
  }

  interfaceToNative(event: EventActionWithNative) {
    const body = JSON.stringify(event);
    const ua = navigator.userAgent.toLowerCase();
    const isiOS = /ipad|iphone|ipod|macintosh|macintel/.test(ua);

    try {
      if (isiOS) {
        window.webkit.messageHandlers.nativeInterface.postMessage(body)
      } else {
        nativeInterface.onRetrievePayloadString(body);
      }
    } catch (error) {
      // console.log(error);
    }
  }
}
