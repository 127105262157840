<ng-container>
    <!-- Box shadow check multiple coupon :D -->
    <img *ngIf="displayImageShadow" class="mask-left-shadow" [src]="couponImage" loading="lazy"
        [attr.data-testid]="prefixId + index">

    <img class="mask-left" [src]="couponImage" loading="lazy" [attr.data-testid]="prefixId + index">

    <!-- Stamp coupon need to comfirm status with BE -->
    <div [ngClass]="{
        'stamp-used': couponStatus === CouponStatus.REDEEM_CONFIRMED, 
        'stamp-expired':  couponStatus === CouponStatus.EXPIRED, 
        'stamp-sold-out': couponStatus === CouponStatus.OUT_OF_QUOTA || couponStatus === CouponStatus.OUT_OF_USED_QUOTA,
        'stamp-tempo-sold-out': couponStatus === CouponStatus.TEMPO_OUT_OF_QUOTA,
        'stamp-unconditional': couponStatus === CouponStatus.NOT_ELIGIBLE_TO_USE 
    }">
    </div>
</ng-container>