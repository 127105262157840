import {
    HttpClient,
    HttpContext,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_ENV_CONFIG } from '../../../environments/app-config.module';
import { EnvironmentConfig } from '../../../environments/app-config.model';

type RestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

@Injectable({
    providedIn: 'root',
})
export class RequestService {
    constructor(
        @Inject(APP_ENV_CONFIG) private environment: EnvironmentConfig,
        private http: HttpClient,
    ) { }
    send<T>(
        method: RestMethod,
        url: string,
        body: any = null,
        params: { [param: string]: string | string[] } = {},
        { useAuth = true, headers = {} as { [key: string]: string } } = {},
        httpContext: HttpContext = new HttpContext()
    ): Observable<any> {
        const serverUrl = this.environment.apiUrl;

        return this.http
            .request<T>(method, `${serverUrl}${url}`, {
                body,
                headers,
                params,
                context: httpContext,
            })
    }

}