<div *ngFor="let i  of [].constructor(numberOfSkeletonCards);">
    <div class="first-section-wrapper coupon-card">
        <div class="wrap-image">
            <ngx-skeleton-loader appearance="circle" [theme]="{
        'width.px': 108,
        'height.px': 108,
        'border-radius': '10px',
        'margin': 0,
        'margin-bottom': '-7px',
        'background': '#F8F8F8'
      }">
            </ngx-skeleton-loader>
        </div>
        <div class="wrap-title">
            <div class="">
                <ngx-skeleton-loader [theme]="{
                    'width': '120px',
                    'height': '15px',
                    'margin-bottom': '6px',
                    'border-radius': '10px',
                    'background': '#F8F8F8'
                }"></ngx-skeleton-loader>
            </div>
            <div>
                <ngx-skeleton-loader [theme]="{ 
                    'width': '100%',
                    'max-width': '220px',
                    'border-radius': '10px', 
                    'margin-top': '-6px',
                    'height': '15px',
                    'background': '#F8F8F8'
                }">
                </ngx-skeleton-loader>
            </div>
        </div>

        <div class="button-pick-coupon">
            <ngx-skeleton-loader [theme]="{ 
            'width': '47px',
            'border-radius': '13px', 
            'height': '24px',
            'background': '#F8F8F8'
        }">
            </ngx-skeleton-loader>
        </div>
    </div>
</div>

<ng-container *ngIf="isloadingSearchCoupon">
    <div data-testid="animation-loading" class="spinner">
        <table>
            <tr>
                <img class="loading-img" src="../../../../../assets/images/CouponLoading.gif" width="80">
            </tr>
            <tr>
                <td><span class="loading-font">กำลังค้นหาคูปอง</span><span class="typed-out">...</span></td>
            </tr>
        </table>
    </div>
</ng-container>