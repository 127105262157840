import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mp-toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss']
})
export class ToastNotificationComponent implements OnInit {
  @Input() customText: string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
