import { Injectable } from "@angular/core";
import { PageRoute } from "@core/routers/route-config";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    constructor() { }

    pageRoute: PageRoute | undefined;
    private _loading: boolean = false;
    loadingStatus: any = new Subject();
    get loading(): boolean {
        return this._loading;
    }
    set loading(val) {
        this._loading = val;

        const loading = {
            isloading: val,
            pageRoute: this.pageRoute
        };

        this.loadingStatus.next(loading)
    }

    startLoading(pageRoute: PageRoute) {
        this.pageRoute = pageRoute;
        setTimeout(() => {
            this.loading = true;
        }, 100);
    }

    stopLoading() {
        setTimeout(() => {
            this.loading = false;
        }, 800);
    }
}