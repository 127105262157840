import { ICouponResponse, IFetchCollectCoupon, IFetchReseverCouponOrder, IPermissionUseCouponResponse } from "./core.model";

export const CORE_COUPON_STATE = 'core-coupon';

export interface CoreCouponState {
    currentPage: string;
    selectedCategoryId: string;
    selectedCoupon: SelectedCoupon;
    stepRouteConfig: string[];
    collectCoupon: ICouponResponse<IFetchCollectCoupon>;
    reserveCouponOrder: ICouponResponse<IFetchReseverCouponOrder>;
    permissionUseCoupon: IPermissionUseCouponResponse;
}

export interface SelectedCoupon {
    couponId: string;
    couponCode?: string;
    hasCountdownTimer?: boolean;
    countdownTimeInSeconds?: number;
    order: number;
    couponStatus?: string;
    formPage?: string;
}