import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PageRoute } from '../configs/route-config';
import { modalWording, ResponseCode } from '../configs/app-config';
import { RootStoreService } from '../root-store/root-store.service';
import { IModalTemplate } from '../model/coupon-modal.model';
import { AnalyticCategory, AnalyticFirebaseParam, AnalyticLabel } from '../core/js-bridge/webview-to-native-interface-enum.model';
import { CouponChannel } from '../core/models/core-enum.model';
import { ModalTemplateComponent } from '../shared/components/modal-template/modal-template.component';
import { take } from 'rxjs/operators';
import { ModalService } from '../service/modal.service';
import { SessionService } from './session.service';
import { CoreCouponFacade } from "../core/core-facade";
import { Store } from '@ngrx/store';
import { AppState } from '../root-store/root-store.model';
import * as RootStoreActions from '../root-store/root-actions';
import { RootStoreEffects } from '../root-store/root-effects';

@Injectable({
    providedIn: 'root'
})
export class HandleErrorService {

    constructor(
        private router: Router,
        private store: Store<AppState>,
        private rootStoreService: RootStoreService,
        private modalService: ModalService,
        private sessionService: SessionService,
        private coreCouponFacade: CoreCouponFacade,
        private rootStoreEffects: RootStoreEffects,
    ) {

    }

    handleStatusErrorResponse(error: HttpErrorResponse) {
        const errorStatusCode = error.status;
        const errorResultCode = error?.error?.result?.code;
        const errorStatusText = error.statusText;
        if (errorResultCode == 1004) {
            this.sessionService.removeTokenSession();
            this.router.navigateByUrl(PageRoute.PATH_UNAUTHORIZED);
        }
        if (errorResultCode == 1007 || errorStatusCode == 404) {
            this.router.navigateByUrl(PageRoute.PATH_NOT_FOUND);
        }
        if ((errorResultCode == 8000) || (errorStatusCode == 403) || (errorStatusCode == 503) || (errorStatusCode == 0 && errorStatusText == "Unknown Error")) {
            this.sessionService.removeTokenSession();
            this.router.navigateByUrl(PageRoute.PATH_UNDER_MAINTENANCE);
        }
    }

    handleAPIErrorResponse(error: HttpErrorResponse) {
        const errorStatusCode = error.status;
        const errorResultCode = error?.error?.result?.code;
        const errorResultMessage = error.error?.result?.message;
        switch (errorResultCode) {
            case ResponseCode.ERROR_API:
            case ResponseCode.ERROR_INVALID_COUPON: {
                const couponChannel = this.rootStoreService.jwtSession?.couponChannel;
                const modalObj: IModalTemplate = {
                    title: modalWording.outOfService,
                    detail: modalWording.pleaseTryAgainLater,
                    analyticModal: {
                        eventCategory: AnalyticCategory.COUPON_API_ERROR,
                        firebaseParam: couponChannel === CouponChannel.NEXT ?
                            [
                                {
                                    index: 5,
                                    key: AnalyticFirebaseParam.HTTP_STATUS_CODE,
                                    value: errorStatusCode
                                },
                                {
                                    index: 6,
                                    key: AnalyticFirebaseParam.ERROR_CODE,
                                    value: errorResultCode
                                },
                                {
                                    index: 7,
                                    key: AnalyticFirebaseParam.IS_FINANCIAL_TRANSACTION_SYSTEM_ERROR,
                                    value: 'False'
                                },
                                {
                                    index: 8,
                                    key: AnalyticFirebaseParam.ERROR_TITLE,
                                    value: errorResultMessage
                                }
                            ] : []
                    },
                    button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
                    deeplinkCallbackUrl: this.rootStoreService.jwtSession!.callbackUrl
                }

                this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe();
                break;
            }
        }
    }

    handleErrorForCouponDetailPageResponse(error: HttpErrorResponse, stepRouteConfig: string[] = []) {
        const errorStatusCode = error.status;
        const errorResultCode = error?.error?.result?.code;
        const errorResultMessage = error.error?.result?.message;

        switch (errorResultCode) {
            case ResponseCode.ERROR_COUPON_NOTFOUND: {
                const modalObj: IModalTemplate = {
                    title: modalWording.notfoundCoupon,
                    detail: "",
                    analyticModal: {
                        eventCategory: AnalyticCategory.COUPON_NOTFOUND,
                    },
                    button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
                    deeplinkCallbackUrl: stepRouteConfig.length <= 1 ? this.rootStoreService.jwtSession?.callbackUrl : undefined
                };

                this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
                    if (stepRouteConfig.length > 1) {
                        const path = stepRouteConfig[stepRouteConfig.length - 2]
                        this.router.navigate([path], { replaceUrl: true });
                        this.coreCouponFacade.removeStepRouteConfig(PageRoute.PATH_COUPON_DETAIL)
                    }
                });
                break;
            }

            case ResponseCode.ERROR_COUPON_DETAIL_ELIGIBLE_NOTFOUND: {
                const couponChannel = this.rootStoreService.jwtSession?.couponChannel;
                const modalObj: IModalTemplate = {
                    title: modalWording.notFoundYourCoupon,
                    detail: "",
                    analyticModal: {
                        eventCategory: AnalyticCategory.COUPON_NOTELIGIBLE,
                    },
                    button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
                    deeplinkCallbackUrl: stepRouteConfig.length <= 1 && couponChannel !== CouponChannel.CNX && couponChannel !== CouponChannel.NEXT ? this.rootStoreService.jwtSession?.callbackUrl : undefined
                }

                this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe(() => {
                    if (stepRouteConfig.length > 1) {
                        const path = stepRouteConfig[stepRouteConfig.length - 2]
                        this.router.navigate([path], { replaceUrl: true });
                        this.coreCouponFacade.removeStepRouteConfig(PageRoute.PATH_COUPON_DETAIL)
                    } else if (couponChannel === CouponChannel.CNX || couponChannel == CouponChannel.NEXT) {
                        this.rootStoreEffects.triggerDestroyRootEffect();
                        this.sessionService.removeWebTokenUrl();
                        this.router.navigate([PageRoute.PATH_HOME], { replaceUrl: true });
                        this.coreCouponFacade.removeStepRouteConfig(PageRoute.PATH_COUPON_DETAIL)
                    }
                });
                break;
            }

            case ResponseCode.ERROR_API:
            case ResponseCode.ERROR_INVALID_COUPON: {
                const couponChannel = this.rootStoreService.jwtSession?.couponChannel;
                const modalObj: IModalTemplate = {
                    title: modalWording.outOfService,
                    detail: modalWording.pleaseTryAgainLater,
                    analyticModal: {
                        eventCategory: AnalyticCategory.COUPON_API_ERROR,
                        firebaseParam: couponChannel === CouponChannel.NEXT ?
                            [
                                {
                                    index: 5,
                                    key: AnalyticFirebaseParam.HTTP_STATUS_CODE,
                                    value: errorStatusCode
                                },
                                {
                                    index: 6,
                                    key: AnalyticFirebaseParam.ERROR_CODE,
                                    value: errorResultCode
                                },
                                {
                                    index: 7,
                                    key: AnalyticFirebaseParam.IS_FINANCIAL_TRANSACTION_SYSTEM_ERROR,
                                    value: 'False'
                                },
                                {
                                    index: 8,
                                    key: AnalyticFirebaseParam.ERROR_TITLE,
                                    value: errorResultMessage
                                }
                            ] : []
                    },
                    button: [{ name: modalWording.ok, action: "", eventLabel: AnalyticLabel.OK }],
                    deeplinkCallbackUrl: this.rootStoreService.jwtSession!.callbackUrl
                }

                this.modalService.openModal(ModalTemplateComponent, { data: modalObj }).pipe(take(1)).subscribe();
                break;
            }
        }

    }
}
