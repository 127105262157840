import { createReducer, on } from "@ngrx/store";
import * as CoreCouponActions from './core.action';
import * as RootStoreType from '../models/core-state.model';
import { cloneDeep } from "lodash";

const initialState: RootStoreType.CoreCouponState = {
    currentPage: '',
    stepRouteConfig: [],
    selectedCategoryId: '0',
    selectedCoupon: {
        couponId: '',
        couponCode: '',
        hasCountdownTimer: false,
        countdownTimeInSeconds: 0,
        order: 0,
        couponStatus: undefined
    },
    collectCoupon: {
        data: {
            couponOrder: undefined,
            numberOfCollectedCoupons: undefined
        },
        result: undefined
    },
    reserveCouponOrder: {
        data: {
            isNavigate: false,
            navigationData: []
        },
        result: undefined
    },
    permissionUseCoupon: {
        permissionUseCoupon: undefined,
        result: undefined,
        status: undefined,
    },
}

export const CoreCouponReducer = createReducer<RootStoreType.CoreCouponState>(
    initialState,
    on(CoreCouponActions.loadCollectCouponSuccess, (state, action) => {
        return {
            ...state,
            collectCoupon: {
                data: action.data.data,
                result: action.data.result
            }
        };
    }),
    on(CoreCouponActions.loadCollectCouponFailure, (state, action) => {
        return {
            ...state,
            collectCoupon: {
                data: {},
                result: action.error.error.result
            }
        }
    }),
    on(CoreCouponActions.clearCollectCoupon, (state, action) => {
        return {
            ...state,
            collectCoupon: {
                data: {
                    couponOrder: undefined,
                    numberOfCollectedCoupons: undefined
                },
                result: undefined
            }
        };
    }),
    on(CoreCouponActions.loadReserveCouponOrderSuccess, (state, action) => {
        return {
            ...state,
            reserveCouponOrder: {
                result: action.data.result,
                data: action.data.data
            }
        };
    }),
    on(CoreCouponActions.loadReserveCouponOrderFailure, (state, action) => {
        return {
            ...state,
            reserveCouponOrder: {
                result: action.error.error.result,
                data: {
                    isNavigate: false,
                    navigationData: []
                }
            }
        }
    }),
    on(CoreCouponActions.clearReserveCouponOrder, (state, action) => {
        return {
            ...state,
            reserveCouponOrder: {
                result: undefined,
                data: {
                    isNavigate: false,
                    navigationData: []
                }
            }
        };
    }),
    on(CoreCouponActions.loadPermissionUseCouponSuccess, (state, action) => {
        return {
            ...state,
            permissionUseCoupon: {
                permissionUseCoupon: action.data.permissionUseCoupon,
                result: action.data.result,
                status: action.data.status,
                sof: action.data.sof,
            }
        };
    }),
    on(CoreCouponActions.loadPermissionUseCouponFailure, (state, action) => {
        return {
            ...state,
            permissionUseCoupon: {
                permissionUseCoupon: false,
                result: action.error.error.result,
                status: action.error.status,
                sof: [],
            }
        }
    }),
    on(CoreCouponActions.clearPermissionUseCoupon, (state, action) => {
        return {
            ...state,
            permissionUseCoupon: {
                permissionUseCoupon: undefined,
                result: undefined,
                status: undefined,
                sof: []
            }
        };
    }),
    on(CoreCouponActions.saveSelectedCoupon, (state, action) => {
        return {
            ...state,
            selectedCoupon: {
                couponId: action.selectedCoupon.couponId,
                couponCode: action.selectedCoupon.couponCode,
                hasCountdownTimer: action.selectedCoupon.hasCountdownTimer,
                countdownTimeInSeconds: action.selectedCoupon.countdownTimeInSeconds,
                order: action.selectedCoupon.order,
                couponStatus: action.selectedCoupon.couponStatus,
                formPage: action.selectedCoupon.formPage ?? undefined
            }
        };
    }),
    on(CoreCouponActions.clearSelectedCoupon, (state, action) => {
        return {
            ...state,
            selectedCoupon: {
                couponId: '',
                couponCode: '',
                hasCountdownTimer: false,
                countdownTimeInSeconds: 0,
                order: 0,
                couponStatus: undefined
            }
        };
    }),
    on(CoreCouponActions.saveCurrentPage, (state, action) => {
        return {
            ...state,
            currentPage: action.currentPage
        }
    }),
    on(CoreCouponActions.saveStepRouteConfig, (state, action) => {
        const isExisting = action.routesUrl.length > 1 ? false : state.stepRouteConfig.includes(action.routesUrl[0]);
        let stateRouting = cloneDeep(state.stepRouteConfig);

        if (isExisting)
            stateRouting = state.stepRouteConfig.filter((routes) => routes !== action.routesUrl[0])

        const stepRouteConfig = {
            ...state,
            stepRouteConfig: [...new Set([...stateRouting, ...action.routesUrl])]
        };

        localStorage.setItem("stepRouteConfig", JSON.stringify(stepRouteConfig.stepRouteConfig));
        return stepRouteConfig;
    }),
    on(CoreCouponActions.removeStepRouteConfig, (state, action) => {

        const stepRouteConfig = {
            ...state,
            stepRouteConfig: state.stepRouteConfig.filter((routes) => routes !== action.routeUrl)
        };

        localStorage.setItem("stepRouteConfig", JSON.stringify(stepRouteConfig.stepRouteConfig));

        return stepRouteConfig;
    }),
    on(CoreCouponActions.clearStepRouteConfig, (state, action) => {
        localStorage.removeItem("stepRouteConfig");
        return {
            ...state,
            stepRouteConfig: []
        };
    }),
    on(CoreCouponActions.saveSelectedCategoryId, (state, action) => {
        return {
            ...state,
            selectedCategoryId: action.selectedCategoryId
        };
    }),
)