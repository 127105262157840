import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ICouponResponse, IFetchCollectCoupon, IFetchReseverCouponOrder, IPermissionUseCouponResponse } from '@core/models/core.model';
import { SelectedCoupon } from '@core/models/core-state.model';

export const loadPermissionUseCoupon = createAction(
    '[Coupon Core] Load Permission Use Coupon',
    props<{ couponId: string, couponOrder: number, checkStatus: boolean }>()
);

export const loadPermissionUseCouponSuccess = createAction(
    '[Coupon Core] Load Permission Use Coupon Success',
    props<{ data: IPermissionUseCouponResponse }>()
);

export const loadPermissionUseCouponFailure = createAction(
    '[Coupon Core] Load Permission Use Coupon Failure',
    props<{ error: HttpErrorResponse }>()
);

export const clearPermissionUseCoupon = createAction(
    '[Coupon Core] Clear Permission Use Coupon',
);

export const loadCollectCoupon = createAction(
    '[Coupon Core] Load Collect Coupon',
    props<{ couponId: string, couponOrder?: number }>()
);

export const loadCollectCouponSuccess = createAction(
    '[Coupon Core] Load Collect Coupon Success',
    props<{ data: ICouponResponse<IFetchCollectCoupon> }>()
);

export const loadCollectCouponFailure = createAction(
    '[Coupon Core] Load Collect Coupon Failure',
    props<{ error: HttpErrorResponse }>()
);

export const clearCollectCoupon = createAction(
    '[Coupon Core] Clear Collect Coupon'
);

export const loadReserveCouponOrder = createAction(
    '[Coupon Core] Load Reserve Coupon Order',
    props<{ couponId: string, couponOrder: number, mode?: string }>()
);

export const loadReserveCouponOrderSuccess = createAction(
    '[Coupon Core] Load Reserve Coupon Order Success',
    props<{ data: ICouponResponse<IFetchReseverCouponOrder> }>()
);

export const loadReserveCouponOrderFailure = createAction(
    '[Coupon Core] Load Reserve Coupon Order Failure',
    props<{ error: HttpErrorResponse }>()
);

export const clearReserveCouponOrder = createAction(
    '[Coupon Core] Clear Reserve Coupon Order'
);

export const saveSelectedCoupon = createAction(
    '[Coupon Core] Save Selected Coupon',
    props<{ selectedCoupon: SelectedCoupon }>()
);

export const clearSelectedCoupon = createAction(
    '[Coupon Core] Clear Selected Coupon'
);

export const saveCurrentPage = createAction(
    '[Coupon Core] Save Current Page',
    props<{ currentPage: string }>()
);

export const saveStepRouteConfig = createAction(
    '[Coupon Core] Save Step Route Config',
    props<{ routesUrl: string[] }>()
);

export const removeStepRouteConfig = createAction(
    '[Coupon Core] Remove Step Route Config',
    props<{ routeUrl: string }>()
);

export const clearStepRouteConfig = createAction(
    '[Coupon Core] Clear Step Route Config'
);

export const saveSelectedCategoryId = createAction(
    '[Coupon Core] Save Selected CategoryId',
    props<{ selectedCategoryId: string }>()
);