import { Component, Input, OnInit } from '@angular/core';
import { PageRoute } from 'src/app/configs/route-config';

@Component({
  selector: 'mp-skeleton-qr-code',
  templateUrl: './skeleton-qr-code.component.html',
  styleUrls: ['./skeleton-qr-code.component.scss']
})
export class SkeletonQrCodeComponent implements OnInit {

  @Input() withPadding: boolean;
  @Input() isAndroid: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
