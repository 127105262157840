import { Store } from '@ngrx/store';
import { AppState } from 'src/app/root-store/root-store.model';
import * as SearchCouponActions from './store/search-coupon.action';
import * as SearchCouponSelectors from './store/search-coupon.selector'
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })

export class SearchCouponFacade {
    constructor(
        private store: Store<AppState>,
    ) { }

    isLoading$ = this.store.select(SearchCouponSelectors.selectIsLoading);
    searchKeyword$ = this.store.select(SearchCouponSelectors.selectSearchKeyword);
    searchDefaultKeyword$ = this.store.select(SearchCouponSelectors.selectDefaultSearchKeyword);
    searchDefaultKeywordIndex$ = this.store.select(SearchCouponSelectors.selectDefaultSearchKeywordIndex);
    couponSearchResult$ = this.store.select(SearchCouponSelectors.selectCouponSearchResult);

    saveSearchKeywordValue(searchKeyword: string) {
        this.store.dispatch(SearchCouponActions.saveSeachKeyword({ keyword: searchKeyword }))
    }

    saveSaveDefaultSeachKeywordIndex(index: number) {
        this.store.dispatch(SearchCouponActions.saveDefaultSeachKeywordIndex({ defaultSearchKeywordIndex: index }))
    }

    loadFetchSearchCoupon(keyWord: string, isLoadingCollectedSuccess: boolean = false) {
        this.store.dispatch(SearchCouponActions.loadSeachCoupon({ keyword: keyWord, isLoadingCollectedSuccess: isLoadingCollectedSuccess }));
    }

    clearSearchCoupon() {
        this.store.dispatch(SearchCouponActions.clearSearchCoupon());
    }

    clearSearchCouponResult() {
        this.store.dispatch(SearchCouponActions.clearSearchCouponResult());
    }
}