<ng-container *transloco="let t">
    <ng-container *ngIf="couponStatus == CouponStatus.REDEEMED">
        <ng-container *ngIf="!isApplyCouponQRCodeONCouponList else buttonApplyCouponQRCodeONCouponList">
            <button class="button font-kani-semibold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                [attr.data-testid]="'btn-collected-redeem-' + index" [id]="'btn-collected-redeem-' + index"
                (click)="numberOfCoupons > 1 && !buttonInOverlay ? openOverlay() : useCoupon()">
                {{ numberOfCoupons > 1 && !buttonInOverlay ? t('coupon_card_use') : t('coupon_card_view_code') }}
            </button>
        </ng-container>
        <ng-template #buttonApplyCouponQRCodeONCouponList>
            <button class="button font-kani-semibold btn-coupon btn-redeem is-pulled-right disabled">
                {{ numberOfCoupons > 1 ? 'ใช้' : 'ดูโค้ด' }}
            </button>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="periodStatus === PeriodStatus.ALLOWED else disabledAction">
        <button *ngIf="couponStatus == CouponStatus.NEW"
            class="button font-kani-semibold btn-coupon btn-collect is-pulled-right disabled-action-button "
            [id]="'btn-collect-' + prefixCouponId" [attr.data-testid]="'btn-collect-' + index" (click)="pickCoupon()">
            {{ t('coupon_card_collect') }}
        </button>

        <ng-container *ngIf="couponUseMethod === CouponMethod.COPAY else QRCoupon">
            <ng-container *ngIf="isInChannel; else NotInChannel">

                <ng-container *ngIf="couponStatus == CouponStatus.COLLECTED">

                    <ng-container *ngIf="isCopayPassFromCouponList; else scanCopay">
                        <!-- Choose copay coupon -->
                        <button
                            class="button font-kani-semibold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                            [attr.data-testid]="'btn-collected-redeem-' + index"
                            [id]="'btn-collected-redeem-' + prefixCouponId"
                            (click)="numberOfCoupons > 1 && !buttonInOverlay ? openOverlay() : useScanCopayCoupon()">
                            {{ t('coupon_card_use') }}
                        </button>
                    </ng-container>

                    <ng-template #scanCopay>
                        <ng-container *ngIf="isUseButtonDisplay && !isCoPayNavigate">
                            <!-- Used copay -->
                            <button
                                class="button font-kani-semibold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                [attr.data-testid]="'btn-collected-redeem-' + index"
                                [id]="'btn-collected-redeem-' + prefixCouponId"
                                (click)="numberOfCoupons > 1 && !buttonInOverlay ? openOverlay() : useScanCopayCoupon()">
                                {{ t('coupon_card_use') }}
                            </button>
                        </ng-container>

                        <ng-container *ngIf="!isUseButtonDisplay && isCoPayNavigate">
                            <ng-container *ngIf="copayNavigation.navigateType === CopayNavigationType.INTERNAL_PT">
                                <!-- need check destination at ngif or at method -->
                                <button *ngIf="copayNavigation.destination === InternalNavigationType.PT_PAYMENT || 
                                    copayNavigation.destination === InternalNavigationType.PT_TOPUP"
                                    class="button font-kani-semibold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                    [attr.data-testid]="'btn-collected-redeem-' + index"
                                    [id]="'btn-collected-redeem-' + prefixCouponId"
                                    (click)="numberOfCoupons > 1 && !buttonInOverlay  ? openOverlay() : usePaymentTopup()">
                                    {{ t('coupon_card_use') }}
                                </button>

                                <button *ngIf="copayNavigation.destination !== InternalNavigationType.PT_PAYMENT && 
                                    copayNavigation.destination !== InternalNavigationType.PT_TOPUP"
                                    class="button font-kani-semibold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                    [attr.data-testid]="'btn-collected-redeem-' + index"
                                    [id]="'btn-collected-redeem-' + prefixCouponId"
                                    (click)="numberOfCoupons > 1 && !buttonInOverlay ? openOverlay() : usePaymentTopup()">
                                    {{ t('coupon_card_use') }}
                                </button>
                            </ng-container>

                            <ng-container *ngIf="copayNavigation.navigateType === CopayNavigationType.EXTERNAL_URL || 
                                copayNavigation.navigateType === CopayNavigationType.EXTERNAL_DEEPLINK">
                                <button
                                    class="button font-kani-semibold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                                    [attr.data-testid]="'btn-collected-redeem-' + index"
                                    (click)="numberOfCoupons > 1 && !buttonInOverlay ? openOverlay() : navigateExternalURL()">
                                    {{ t('coupon_card_use') }}
                                </button>
                            </ng-container>
                        </ng-container>
                    </ng-template>
                </ng-container>
            </ng-container>

            <ng-template #NotInChannel>
                <button *ngIf="couponStatus == CouponStatus.COLLECTED"
                    class="button font-kani-semibold btn-coupon btn-redeem is-pulled-right disabled">
                    {{ t('coupon_card_use') }}
                </button>
            </ng-template>
        </ng-container>

        <ng-template #QRCoupon>
            <ng-container *ngIf="couponStatus == CouponStatus.COLLECTED">
                <ng-container *ngIf="isInChannel; else NotInQRCodeChannel">
                    <button
                        class="button font-kani-semibold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                        [attr.data-testid]="'btn-collected-redeem-' + index"
                        (click)="numberOfCoupons > 1 && !buttonInOverlay ? openOverlay() : useCoupon()">
                        {{ t('coupon_card_use') }}
                    </button>
                </ng-container>

                <ng-template #NotInQRCodeChannel>
                    <button class="button font-kani-semibold btn-coupon btn-redeem is-pulled-right disabled">
                        {{ t('coupon_card_use') }}
                    </button>
                </ng-template>
            </ng-container>

            <!-- </ng-container> -->

            <!-- Use QR Code -->
            <!-- <button *ngIf="couponStatus == CouponStatus.COLLECTED"
                class="button font-kani-semibold btn-coupon btn-redeem is-pulled-right disabled-action-button"
                [attr.data-testid]="'btn-collected-redeem-' + index"
                (click)="numberOfCoupons > 1 && !buttonInOverlay ? openOverlay() : useCoupon()">
                {{ t('coupon_card_use') }}
            </button> -->
        </ng-template>

    </ng-container>

    <!-- Disabled Action -->

    <ng-template #disabledAction>
        <button
            *ngIf="couponStatus === CouponStatus.NEW && 
                (periodStatus === PeriodStatus.PERIOD || periodStatus === PeriodStatus.DATE || periodStatus === PeriodStatus.START_COLLECT)"
            class="button font-kani-semibold btn-coupon btn-redeem is-pulled-right disabled"
            [attr.data-testid]="'btn-disable-collect-' + index">
            {{ t('coupon_card_collect') }}
        </button>

        <button
            *ngIf="couponStatus === CouponStatus.COLLECTED && 
                (periodStatus === PeriodStatus.PERIOD || periodStatus === PeriodStatus.DATE || periodStatus === PeriodStatus.START_REDEEM)"
            class="button font-kani-semibold btn-coupon btn-redeem is-pulled-right disabled"
            [attr.data-testid]="'btn-disable-redeem-' + index">
            {{ t('coupon_card_use') }}
        </button>

    </ng-template>
</ng-container>