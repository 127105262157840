import { Component, OnInit, ViewChild } from '@angular/core';
import { PageRoute } from '@core/routers/route-config';
import { LoadingService } from '@utils/loading.service';
import { isNil } from 'lodash';
import { filter, Subject } from 'rxjs';


@Component({
  selector: 'mp-skeleton-page',
  templateUrl: './skeleton-page.component.html',
  styleUrls: ['./skeleton-page.component.scss']
})
export class SkeletonPageComponent implements OnInit {

  @ViewChild('loadingPage') loadingPage: any;
  isAndroid: boolean = /(android)/i.test(navigator.userAgent);
  path: PageRoute;
  withPadding: boolean = true; // this.rootStoreService.jwtSession?.withHeader ?? true;
  PageRoute = PageRoute;
  loading: boolean = false;
  private _destroy = new Subject<void>();


  constructor(
    private loadingService: LoadingService,
  ) {

    this.loadingService.loadingStatus.pipe(filter((v: any) => !isNil(v))).subscribe((value: any) => {
      this.path = value.pageRoute;

      if (this.path === undefined || this.path === PageRoute.SEARCH_COUPON || this.path == PageRoute.APPLY_COUPON) {
        this.loading = false;
        return;
      }

      if (this.loading == !value.isloading) {
        this.loading = value.isloading;
      }
    })
  }

  ngOnInit(): void {

  }

  toggleContent(val: any) {
    this.loading = val;

    if (this.loading) {
      this.loadingPage.nativeElement.hidden = false;
    } else {
      this.loadingPage.nativeElement.hidden = true;
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
