import { ICouponHomeModel } from "../../coupon-home/models/coupon-home.model";

export const SEARCH_COUPON_STATE = 'search-coupon';

export interface SearchCouponState {
    isLoading: boolean;
    keyword: string;
    defaultSearchKeyword: string[];
    searchResult: ICouponHomeModel[] | undefined;
    defaultSearchKeywordIndex: number;
}