import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { CouponHeaderTemplate } from '@core/configs/coupon-card-config';
import { Mode } from '@core/configs/mode-enum-config';
import { CouponEventTagAction } from '@core/ga-tagging/coupon-ga.model';
import { CouponGAService } from '@core/ga-tagging/coupon-ga.service';
import { AnalyticAction, AnalyticFirebaseParam } from '@core/js-bridge/webview-to-native-interface-enum.model';
import { WebviewToNativeInterfaceService } from '@core/js-bridge/webview-to-native-interface.service';
import { CouponChannel } from '@core/models/core-enum.model';
import { RouterService } from '@core/routers/router.service';

@Component({
  selector: 'mp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ContentChild('trailing', { static: true, read: TemplateRef })
  trailingTemplate!: TemplateRef<any>;
  isFirstPath: boolean = this.routerService.isFirstPath();
  @Input() mode: string;
  @Input() withHeader: boolean = true;
  @Input() headerTitle: string;
  @Input() couponHeaderTemplate: string;
  @Input() categoryEvent: string;
  @Input() couponChannel: string;
  @Input() callbackUrl: string = '';
  @Input() callbackDestination: string;
  @Input() couponTitle: string;
  @Input() campaignCode: string;
  @Output() onRemoveStepRouteConfig: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSetStepRouteConfig: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onJSBridgeBack: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSpecificBack: EventEmitter<void> = new EventEmitter<void>();
  CouponHeaderTemplate = CouponHeaderTemplate;
  CouponChannel = CouponChannel;
  Mode = Mode;
  constructor(
    private couponGAService: CouponGAService,
    private routerService: RouterService,
    private webviewToNativeInterfaceService: WebviewToNativeInterfaceService

  ) { }

  ngOnInit(): void {
  }

  back() {
    this.routerService.back({ replaceUrl: true });
    this.onRemoveStepRouteConfig.emit();
  }

  setAnalyticBack() {
    this.setGATag('Back');
    this.webviewToNativeInterfaceService.trackAction(
      {
        action: AnalyticAction.CLICK,
        category: this.categoryEvent,
        label: `${this.categoryEvent}_Back`,
        firebase_screen: this.categoryEvent,
      }
    );
  }

  setGATag(label: string, campaignCode: string = '') {
    const eventLabel = `${this.categoryEvent}_${label}`;
    this.trackActionGATag(eventLabel, campaignCode);
  }

  navigateBackWithJsbridge() {
    this.onJSBridgeBack.emit();
  }

  private trackActionGATag(label: string, campaignCode: string = '') {
    const gaObj: CouponEventTagAction = {
      eventName: this.categoryEvent,
      eventCategory: this.categoryEvent,
      eventLabel: label,
      eventAction: 'Click',
      campaignCode: campaignCode,
      couponChannel: this.couponChannel ?? '',
    }
    this.couponGAService.trackActionGATag(gaObj)
  }

}
