import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Session } from './root-store.model';
export const selectFeature = createFeatureSelector<Session>('session');
export const selectSession = createSelector(
  selectFeature,
  (state: Session) => state
);

export const selectWebToken = createSelector(
  selectFeature,
  (state: Session) => state.webToken
);

export const selectAuthorizationCode = createSelector(
  selectFeature,
  (state: Session) => state.authorizationCode
);

export const selectAccessToken = createSelector(
  selectFeature,
  (state: Session) => state.accessToken
);

export const selectJWTSession = createSelector(
  selectFeature,
  (state: Session) => state.jwtSession
);

export const selectCouponChannel = createSelector(
  selectJWTSession,
  (jwtSession) => jwtSession.couponChannel
);

export const selectStatus = createSelector(
  selectJWTSession,
  (jwtSession) => jwtSession.status
);

export const selectCallbackUrl = createSelector(
  selectJWTSession,
  (jwtSession) => jwtSession.callbackUrl
);

export const selectRegisterCallbackUrl = createSelector(
  selectJWTSession,
  (jwtSession) => jwtSession.registerCallbackUrl
);

export const selectProcessingCallbackUrl = createSelector(
  selectJWTSession,
  (jwtSession) => jwtSession.processingCallbackUrl
)

export const selectWithHeader = createSelector(
  selectJWTSession,
  (jwtSession) => jwtSession.withHeader
);

export const selectPaymentCallbackUrl = createSelector(
  selectJWTSession,
  (jwtSession) => jwtSession.paymentCallbackUrl
);

export const selectChooseCouponCallbackUrl = createSelector(
  selectJWTSession,
  (jwtSession) => jwtSession.chooseCouponCallbackUrl
);

export const selectIsInitialised = createSelector(
  selectFeature,
  (state: Session) => state.isInitialised
);