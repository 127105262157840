import { Component, Input, OnInit } from '@angular/core';
import { BadgeLabel } from '@core/models/core-enum.model';

@Component({
  selector: 'mp-badge-label',
  templateUrl: './badge-label.component.html',
  styleUrls: ['./badge-label.component.scss']
})
export class BadgeLabelComponent implements OnInit {

  @Input() badgeLabel: string;
  BadgeLabel = BadgeLabel;
  constructor() { }

  ngOnInit(): void {
  }

}
