import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SearchCouponFacade } from '@features/search-coupon/search-coupon-facade';
import { CouponCardTemplate } from 'src/app/configs/coupon-card-config';

@Component({
  selector: 'mp-form-search-coupon',
  templateUrl: './form-search-coupon.component.html',
  styleUrls: ['./form-search-coupon.component.scss']
})
export class FormSearchCouponComponent implements OnInit {
  @Input() couponTemplate: string
  @Input() keyword: string = '';
  @Input() defaultSearchKeyword: string[] = [];
  @Input() defaultSearchKeywordIndex: number = 0;
  @Output() onActionSearchForHomePage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onActionEmitKeyword: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('inputSearch', { static: false }) inputSearch: ElementRef;
  CouponCardTemplate = CouponCardTemplate;
  placeholderIndex: number = 0;
  placeholderInterval: any;
  placeholders: string[] = [];
  defaultPlaceHolder = 'ค้นหาคูปองสุดคุ้มเลย!';
  currentPlaceholder: string = '';

  constructor(
    private searchCouponFacade: SearchCouponFacade,
  ) { }

  ngOnInit(): void {
    this.placeholders = this.defaultSearchKeyword.length > 0 ? this.defaultSearchKeyword : [this.defaultPlaceHolder];
    if (this.placeholders.length >= this.defaultSearchKeywordIndex + 1) {
      this.currentPlaceholder = this.placeholders[this.defaultSearchKeywordIndex];
      this.placeholderIndex = this.defaultSearchKeywordIndex;
    } else {
      this.currentPlaceholder = this.placeholders[0];
    }
    this.startPlaceholder();
  }

  ngOnDestroy(): void {
    this.stopPlaceholder();
  }

  goSearch() {
    this.searchCouponFacade.saveSaveDefaultSeachKeywordIndex(this.placeholderIndex)
    this.onActionSearchForHomePage.emit(true)
  }

  searchCouponAction() {
    this.searchCouponFacade.saveSaveDefaultSeachKeywordIndex(this.placeholderIndex)
    this.onActionEmitKeyword.emit(this.keyword)
  }

  onSearchChange() {
    if (this.keyword.length > 0) {
      this.stopPlaceholder()
    } else {
      this.startPlaceholder()
    }
  }

  clearKeyword() {
    this.keyword = "";
  }

  startPlaceholder(): void {
    if (this.placeholders.length > 1) {
      this.placeholderInterval = setInterval(() => {
        this.placeholderIndex = (this.placeholderIndex + 1) % this.placeholders.length;
        this.currentPlaceholder = this.placeholders[this.placeholderIndex];
      }, 5000);
    }
  }

  stopPlaceholder(): void {
    if (this.placeholderInterval) {
      clearInterval(this.placeholderInterval);
    }
  }

}
