<div class="wrap-bg">
    <div id="qr-code-content" [ngClass]="{'notification-android' : isAndroid, ' mg-CNX': withPadding}"
        class="notification">
        <div class="container">
            <div class="columns is-gapless gap-content-start">
                <div class="coupon-title font-kani-semibold">
                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                        'height': '24px', 
                        'width': '100%' ,
                        'margin-top': '8px',
                        'margin-bottom': '8px',
                        'background': 'var(--gray-light-disabled)',
                        'display' : 'block'
                        }">
                    </ngx-skeleton-loader>
                </div>
            </div>

            <div class="button is-medium is-fullwidth section-tab">
                <div class="columns is-mobile is-fullwidth">
                    <div class="column has-text-left tab-item">
                        <div class="item">
                            <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
                                    'width': '50%',
                                    'height': '28px',
                                    'border-radius': '20px',
                                    'margin' : '0px',
                                    'background': 'var(--gray-light-disabled)',
                                    'vertical-align': 'middle',
                                    'display' : 'block'
                                  }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>

            <div class="has-text-centered">
                <div>
                    <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
                        'width': '56px',
                        'height': '56px',
                        'border-radius': '8px',
                        'margin': '16px auto 0',
                        'background': 'var(--white-gray-color)',
                        'display' : 'block'
                        }">
                    </ngx-skeleton-loader>
                </div>

                <div>
                    <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
                        'width': '200px',
                        'height': '200px',
                        'border-radius': '8px',
                        'margin': '16px auto 0',
                        'background': 'var(--white-gray-color)',
                        'display' : 'block'
                        }">
                    </ngx-skeleton-loader>
                </div>

                <div>
                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                        'width': '160px',
                        'height': '24px',
                        'border-radius': '6px',
                        'margin': '16px auto 0',
                        'background': 'var(--white-gray-color)',
                        'display' : 'block'
                        }">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                        'width': '100%',
                        'height': '1px',
                        'margin': '24px auto',
                        'background': 'var(--gray-light-disabled)',
                        'display' : 'block'
                        }">
                    </ngx-skeleton-loader>
                </div>
                <div>
                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                        'width': '200px',
                        'height': '24px',
                        'border-radius': '6px',
                        'margin': '0 auto',
                        'background': 'var(--white-gray-color)',
                        'display' : 'block'
                        }">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                        'width': '100%',
                        'height': '48px',
                        'border-radius': '8px',
                        'margin': '16px auto 0',
                        'background': 'var(--white-gray-color)',
                        'display' : 'block'
                        }">
                    </ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</div>