import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    CoreCouponState,
    CORE_COUPON_STATE,
} from '../models/core-state.model';

export const selectState = createFeatureSelector<CoreCouponState>(CORE_COUPON_STATE);

export const selectCollectCoupon = createSelector(
    selectState,
    (state) => state.collectCoupon
)

export const selectReserveCouponOrder = createSelector(
    selectState,
    (state) => state.reserveCouponOrder
)

export const selectPermissionUseCoupon = createSelector(
    selectState,
    (state) => state.permissionUseCoupon
);

export const selectCurrentPage = createSelector(
    selectState,
    (state) => state.currentPage
);

export const selectedCoupon = createSelector(
    selectState,
    (state) => state.selectedCoupon
);

export const selectedCouponId = createSelector(
    selectedCoupon,
    (selectedCoupon) => selectedCoupon.couponId
);

export const selectStepRouteConfig = createSelector(
    selectState,
    (state) => state.stepRouteConfig
);

export const selectCategoryId = createSelector(
    selectState,
    (state) => state.selectedCategoryId
);