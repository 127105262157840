import { forwardRef, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as RootSelectors from './root-selectors';
import { AppState, ICouponAccressToken, JWTSession, Session } from './root-store.model';
import { RootStoreModule } from './root-store.module';
import { map, Observable, of } from 'rxjs';
import { ICouponResponse } from '../model/coupon-model.model';
import { RequestService } from '@core/http/request.service';

@Injectable({ providedIn: forwardRef(() => RootStoreModule) })
export class RootStoreService {
  session: Session | undefined;
  jwtSession: JWTSession | undefined;
  session$ = this.store.select(RootSelectors.selectSession);
  jwtSession$ = this.store.select(RootSelectors.selectJWTSession);
  couponChannelSession$ = this.store.select(RootSelectors.selectCouponChannel);
  accessToken$ = this.store.select(RootSelectors.selectAccessToken);
  isInitialised$ = this.store.select(RootSelectors.selectIsInitialised);

  constructor(
    private requestService: RequestService,
    private store: Store<AppState>
  ) {
    this.jwtSession$.subscribe((jwtSession) => {
      this.jwtSession = jwtSession;
    });
  }

  postAuthVerify(authorizationCode: string, webConfig: string): Observable<ICouponResponse<ICouponAccressToken>> {
    return this.requestService.send<ICouponResponse<ICouponAccressToken>>(
      'POST',
      '/api/v1/mp/token/auth/core-web',
      { authorizationCode: authorizationCode, webConfig: webConfig }
    ).pipe(
      map((response: ICouponResponse<ICouponAccressToken>) => { return response })
    );
  }
}
