import { Injectable } from "@angular/core";
import * as Lodash from "lodash";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import {
    catchError,
    map,
    switchMap,
    tap,
    withLatestFrom,
    take,
    shareReplay,
} from 'rxjs/operators';
import * as CoreActions from './core.action';
import { AppState } from "src/app/root-store/root-store.model";
import { UtilService } from "src/app/service/util.service";
import { HttpErrorResponse } from "@angular/common/http";
import { EMPTY } from "rxjs";
import { ICouponResponse, IFetchCollectCoupon, IFetchCouponDetail, IFetchReseverCouponOrder } from "../models/core.model";
import { CouponStatus, PeriodStatus } from "../models/core-enum.model";
import { CoreService } from "../services/core.service";
import { HandleErrorService } from "../../utils/handle-error.service";

@Injectable()
export class CoreCouponEffect {
    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private utilService: UtilService,
        private coreService: CoreService,
        private handleErrorService: HandleErrorService

    ) { }

    loadPermissionUseCouponEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CoreActions.loadPermissionUseCoupon),
            switchMap((action) => {
                return this.coreService.fetchCouponDetail(action.couponId, action.couponOrder).pipe(
                    catchError((err: HttpErrorResponse) => {
                        this.handleErrorService.handleStatusErrorResponse(err);
                        if (err.status === 500 || err.status === 400) {
                            this.handleErrorService.handleAPIErrorResponse(err);
                            return EMPTY;
                        }
                        this.store.dispatch(CoreActions.loadPermissionUseCouponFailure({ error: err }));
                        return EMPTY;
                    }),
                    map((response: ICouponResponse<IFetchCouponDetail>) => {

                        let permissionUseCoupon: boolean = false;

                        if (action.checkStatus) {
                            permissionUseCoupon = (response.data.coupon?.periodStatus === PeriodStatus.ALLOWED && response.data.coupon.status === CouponStatus.COLLECTED)
                        } else {
                            permissionUseCoupon = response.data.coupon?.periodStatus === PeriodStatus.ALLOWED ? true : false;
                        }

                        const res = {
                            permissionUseCoupon: permissionUseCoupon,
                            result: response.result,
                            status: response.data.coupon?.status,
                            sof: response.data.coupon?.campaignSOFList || [],
                        }
                        return CoreActions.loadPermissionUseCouponSuccess({
                            data: res,
                        });
                    })
                );
            })
        )
    });

    loadCollectCouponEffects$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CoreActions.loadCollectCoupon),
            switchMap((action) => {
                return this.coreService.fetchCollectCoupon(action.couponId).pipe(
                    catchError((err: HttpErrorResponse) => {
                        this.utilService.stopLoading();
                        this.handleErrorService.handleStatusErrorResponse(err);
                        this.handleErrorService.handleAPIErrorResponse(err);
                        this.store.dispatch(CoreActions.loadCollectCouponFailure({ error: err }));
                        return EMPTY;
                    }),
                    map((response: ICouponResponse<IFetchCollectCoupon>) => {
                        return CoreActions.loadCollectCouponSuccess({
                            data: response,
                        })
                    })
                );
            })
        )
    });

    loadReserveCouponOrderEffects$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CoreActions.loadReserveCouponOrder),
            switchMap((action) => {
                return this.coreService.reseverCouponOrder(action.couponId, action.couponOrder, action.mode).pipe(
                    catchError((err: HttpErrorResponse) => {
                        this.utilService.stopLoading();
                        this.handleErrorService.handleStatusErrorResponse(err);
                        this.handleErrorService.handleAPIErrorResponse(err);
                        this.store.dispatch(CoreActions.loadReserveCouponOrderFailure({ error: err }));

                        return EMPTY;
                    }),
                    map((response: ICouponResponse<IFetchReseverCouponOrder>) => {
                        return CoreActions.loadReserveCouponOrderSuccess({
                            data: response,
                        })
                    })
                );
            })
        )
    });
}