import { ApiEndpoint, Environment, EnvironmentConfig, PTCallBackUrl } from "./app-config.model";

// export const environment = {
//   production: false,
//   // api_url: "https://extgw-membership-sit.th-service.co.in",
//   api_url: "https://extgw-membership-aws-sit.arisetech.dev",
//   tracking_id: 'GTM-WSFVH3P',
//   callbackURL: 'paotang://paotang.co.th/coupon.webview.callback.deeplink'
// };

export const environment: EnvironmentConfig = {
  name: Environment.SIT,
  isProduction: false,
  apiUrl: ApiEndpoint.SIT,
  callbackURL: PTCallBackUrl.SIT,
  trackingId: 'GTM-WSFVH3P',
}
