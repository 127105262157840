export enum Environment {
    LOCAL = 'local',
    SIT = 'sit',
    UAT = 'uat',
    PRODUCTION = 'prod',
}

export enum ApiEndpoint {
    LOCAL = '',
    SIT = 'https://extgw-membership-sit.th-service.co.in',
    UAT = 'https://extgw-membership-uat.th-service.co.in',
    PRODUCTION = 'https://api.coupon.krungthai.com',
}

export enum PTCallBackUrl {
    LOCAL = 'paotang://paotang.co.th/coupon.webview.callback.deeplink',
    SIT = 'paotang://paotang.co.th/coupon.webview.callback.deeplink',
    UAT = 'paotang://paotang.co.th/coupon.webview.callback.deeplink',
    PRODUCTION = 'paotang://paotang.co.th/coupon.webview.callback.deeplink',
}

export interface EnvironmentConfig {
    name: string;
    isProduction: boolean;
    apiUrl: string;
    trackingId: string;
    callbackURL: string;
}