import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageRoute } from 'src/app/configs/route-config';

@Component({
  selector: 'mp-skeleton-header',
  templateUrl: './skeleton-header.component.html',
  styleUrls: ['./skeleton-header.component.scss']
})
export class SkeletonHeaderComponent implements OnInit {
 
  @Input() withPadding: boolean;
  @Input() path: PageRoute;
  PageRoute = PageRoute;
  constructor(
  ) { }

  ngOnInit(): void {

  }

}
