import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mp-skeleton-card',
  templateUrl: './skeleton-card.component.html',
  styleUrls: ['./skeleton-card.component.scss']
})
export class SkeletonCardComponent implements OnInit {

  @Input() isloadingSearchCoupon: boolean = false;
  @Input() numberOfSkeletonCards: number = 10;
  constructor() { }

  ngOnInit(): void {
  }

}
