<p-dialog
  [showHeader]="!!headerTitle || isClosed"
  [header]="headerTitle"
  [contentStyleClass]="'content-slide-dialog'"
  [modal]="true"
  [(visible)]="display"
  [style]="{ width: '100vw' }"
  position="bottom"
  class="dialog-content"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  [closable]="hideOnOutsideClick"
  (onHide)="hideOnOutsideClick ? closeEvt() : null"
>
  <ng-template pTemplate="header">
    <div class="header card">
      <ng-container *ngIf="headerTemplate">
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
      </ng-container>
    </div>
  </ng-template>

  <div class="content-card">
    <ng-container *ngIf="contentTemplate">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </ng-container>
  </div>
</p-dialog>