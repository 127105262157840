<ng-container *transloco="let t">
    <div class="section-progress">
        <div
            *ngIf="(deductionType === DeductionType.COLLECTED && couponStatus === CouponStatus.NEW) || deductionType === DeductionType.REDEEMED">
            <div class="is-inline-flex">
                <progress class="progress orange" [value]="progressionPercent" max="100"></progress>
                <div class="v-align-top">
                    <div class="pl-2">
                        <ng-container *ngIf="couponStatus === CouponStatus.COLLECTED else otherStatus">
                            <span class="text-progress">{{ t('used') }}</span>
                        </ng-container>

                        <ng-template #otherStatus>
                            <ng-container *ngIf="deductionType === DeductionType.COLLECTED">
                                <span class="text-progress">{{ t('coupon_detail_collected') }}</span>
                            </ng-container>

                            <ng-container *ngIf="deductionType === DeductionType.REDEEMED">
                                <span class="text-progress">{{ t('used') }}</span>
                            </ng-container>

                        </ng-template>

                        <span class="text-progress-num orange">
                            {{ progressionPercent }}%</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>