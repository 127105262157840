<div
    [class]="'header flex justify-center items-center h-auto px-4 text-center ' + (withHeader ? ' header-height' : ' without-header')">
    <!-- header has-text-centered header-flex -->
    <ng-container [ngSwitch]="couponHeaderTemplate">
        <ng-container *ngSwitchCase="CouponHeaderTemplate.COUPON_HOME">
            <ng-container *ngTemplateOutlet="couponHomeHeaderTemplate"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="CouponHeaderTemplate.MY_COUPON">
            <ng-container *ngTemplateOutlet="myCouponHeaderTemplate"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="CouponHeaderTemplate.COUPON_LIST">
            <ng-container *ngTemplateOutlet="couponListHeaderTemplate"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="CouponHeaderTemplate.COUPON_DETAIL">
            <ng-container *ngTemplateOutlet="couponDetailHeaderTemplate"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="CouponHeaderTemplate.SEARCH_COUPON">
            <ng-container *ngTemplateOutlet="formCouponHeaderTemplate"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="CouponHeaderTemplate.APPLY_COUPON">
            <ng-container *ngTemplateOutlet="formCouponHeaderTemplate"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="CouponHeaderTemplate.QR_CODE">
            <ng-container *ngTemplateOutlet="qrCodeCouponHeaderTemplate"></ng-container>
        </ng-container>
    </ng-container>

    <div *ngIf="!withHeader && couponHeaderTemplate == CouponHeaderTemplate.COUPON_HOME" style="width: 40px;"></div>
    <div class="header-title">
        <h3>{{ headerTitle }}</h3>
    </div>

    <div *ngIf="couponHeaderTemplate !== CouponHeaderTemplate.COUPON_HOME" class="header-temp-last"></div>

    <ng-container *ngIf="trailingTemplate">
        <ng-container *ngTemplateOutlet="trailingTemplate"></ng-container>
    </ng-container>

    <ng-content select="[custom-container]"></ng-content>

</div>

<ng-template #couponHomeHeaderTemplate>
    <div class="has-text-left" *ngIf="withHeader" style="width: 44px;">
        <a [href]="callbackUrl | safe: 'url'" class="link-item" data-testid="link-back-icon"
            (click)="setAnalyticBack()">
            <img src="../../../../assets/icon/ic_chevron-left-2.png" alt="ic_search" width="24" height="24"
                class="ic-color">
        </a>
    </div>
</ng-template>

<ng-template #couponListHeaderTemplate>
    <div class="has-text-left w-[30px]">
        <a [href]="callbackUrl | safe: 'url'" (click)="setAnalyticBack(); onRemoveStepRouteConfig.emit();"
            class="link-item" data-testid="link-callback-icon">
            <img src="../../../../assets/icon/ic_chevron-left-2.png" alt="ic_search" width="24" height="24"
                class="ic-color">
        </a>
    </div>
</ng-template>

<ng-template #myCouponHeaderTemplate>
    <div class="has-text-left  w-[30px]">
        <ng-container *ngIf="!isFirstPath; else callBackMissionBaseOnNEXT">
            <a (click)="setAnalyticBack(); back()" data-testid="my-coupon-back1">
                <img src="../../../../assets/icon/ic_chevron-left-2.png" alt="ic_search" width="24" height="24"
                    class="ic-color">
            </a>
        </ng-container>

        <ng-template #callBackMissionBaseOnNEXT>
            <ng-container *ngIf="callbackDestination && couponChannel == CouponChannel.NEXT; else callBackUrl">
                <a (click)="setAnalyticBack(); navigateBackWithJsbridge();" data-testid="my-coupon-back3">
                    <img src="../../../../assets/icon/ic_chevron-left-2.png" alt="ic_search" width="24" height="24"
                        class="ic-color">
                </a>
            </ng-container>
        </ng-template>

        <ng-template #callBackUrl>
            <a [href]="callbackUrl | safe : 'url'" (click)="setAnalyticBack()" data-testid="my-coupon-back2">
                <img src="../../../../assets/icon/ic_chevron-left-2.png" alt="ic_search" width="24" height="24"
                    class="ic-color">
            </a>
        </ng-template>
    </div>
</ng-template>

<ng-template #couponDetailHeaderTemplate>
    <div class="has-text-left  w-[30px]">
        <ng-container *ngIf="!isFirstPath && mode !== Mode.CANCEL; else callBackMissionBaseOnNEXT">
            <a (click)="setAnalyticBack(); back()" data-testid="coupon-detail-back" id="CouponDetails_Back">
                <img src="../../../../assets/icon/ic_chevron-left-2.png" alt="ic_search" width="24" height="24"
                    class="ic-color">
            </a>
        </ng-container>

        <ng-template #callBackMissionBaseOnNEXT>
            <ng-container
                *ngIf="callbackDestination && couponChannel === CouponChannel.NEXT && mode !== Mode.CANCEL; else callBackUrl">
                <a (click)="setAnalyticBack(); navigateBackWithJsbridge();"
                    data-testid="CouponDetails_Back_Callback_MB">
                    <img src="../../../../assets/icon/ic_chevron-left-2.png" alt="ic_search" width="24" height="24"
                        class="ic-color">
                </a>
            </ng-container>
        </ng-template>

        <ng-template #callBackUrl>
            <a [href]="callbackUrl | safe: 'url'"
                (click)="mode === Mode.CANCEL ? onSetStepRouteConfig.emit(true) : null; setAnalyticBack();"
                data-testid="link-callback-icon" id="CouponDetails_Back_Callback">
                <img src="../../../../assets/icon/ic_chevron-left-2.png" alt="ic_search" width="24" height="24"
                    class="ic-color">
            </a>
        </ng-template>
    </div>
</ng-template>

<ng-template #formCouponHeaderTemplate>
    <div class="has-text-left  w-[30px]">
        <a (click)="setAnalyticBack(); back()" data-testid="form-coupon-back">
            <img src="../../../../assets/icon/ic_chevron-left-2.png" alt="ic_search" width="24" height="24"
                class="ic-color">
        </a>
    </div>
</ng-template>

<ng-template #qrCodeCouponHeaderTemplate>
    <div class="has-text-left  w-[30px]">
        <a (click)="setAnalyticBack(); onSpecificBack.emit();" id="CouponCodeScreen_Back"
            data-testid="coupon-back-button">
            <img src="../../../../assets/icon/ic_chevron-left-2.png" alt="ic_search" width="24" height="24"
                class="ic-color">
        </a>
    </div>
</ng-template>