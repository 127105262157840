<ng-container [ngSwitch]="applyCouponTemplate">
    <ng-container *ngSwitchCase="CouponCardTemplate.COUPON_HOME">
        <ng-container *ngTemplateOutlet="couponHomeCardTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="CouponCardTemplate.APPLY_COUPON">
        <ng-container *ngTemplateOutlet="applyCouponCardTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="defaultCardTemplate"></ng-container>
    </ng-container>

</ng-container>


<ng-template #couponHomeCardTemplate>
    <div class="search-input" (click)="goApplyCoupon()" [attr.data-testid]="'home-apply-coupon'">
        <img src="../../../../assets/icon/ic_coupon.png" alt="ic_search" width="24" height="24" class="ic-color">
        <input [attr.data-testid]="'home-input-apply-coupon'" type="text" [placeholder]="'ระบุโค้ด'" readonly>
    </div>
</ng-template>

<ng-template #defaultCardTemplate>
    <div class="search-input-code" (click)="goApplyCoupon()" [attr.data-testid]="'default-apply-coupon'">
        <img src="../../../../assets/icon/ic_coupon.png" alt="ic_search" width="24" height="24" class="ic-color">
        <input [attr.data-testid]="'default-input-apply-coupon'" type="text" [placeholder]="'ระบุโค้ดเพื่อเก็บคูปอง'"
            readonly>
    </div>
</ng-template>

<ng-template #applyCouponCardTemplate>
    <div class="search-input-code">
        <img src="../../../../assets/icon/ic_coupon.png" alt="ic_search" width="24" height="24" class="ic-color">
        <input #inputSearch [attr.data-testid]="'apply-coupon-form'" [(ngModel)]="keyword" type="text"
            [placeholder]="'ระบุโค้ดเพื่อเก็บคูปอง'" [maxlength]="30"enterkeyhint="done"
            (keydown.enter)="searchCouponAction()"
            (keydown.shift.enter)="searchCouponAction()"
        >
        <img class="pointer" src="../../../../assets/images/ic_close-circle.png" alt="ic_close-circle" width="24"
            height="24" *ngIf="keyword.length > 0" (click)="clearKeyword()" [attr.data-testid]="'clear-keyword-button'">
    </div>
</ng-template>