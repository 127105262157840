import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from '@ngrx/effects';
import * as RootStoreType from '../root-store/root-store.model';
import * as CoreStoreReducer from './store/core.reducer';
import { CoreCouponEffect } from "./store/core.effects";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { HeaderInjector } from "./interceptors/headers.interceptor";
import { I18nModule } from "./i18n/i18n.module";
import { GtagModule } from "angular-gtag";
import { environment } from "@environments/environment";

@NgModule({
    declarations: [],
    imports: [
        I18nModule,
        HttpClientModule,
        GtagModule.forRoot({ trackingId: environment.trackingId, trackPageviews: true }),
        StoreModule.forFeature(
            RootStoreType.CORE_COUPON_STATE,
            CoreStoreReducer.CoreCouponReducer
        ),
        EffectsModule.forFeature([CoreCouponEffect]),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInjector,
            multi: true
        }
    ]
})
export class CoreModule { }