import { createAction, props } from '@ngrx/store';
import { ICouponHomeModel } from '../../coupon-home/models/coupon-home.model';

export const loadSeachCoupon = createAction(
    '[Search Coupon] Load Search Coupon',
    props<{ keyword: string, isLoadingCollectedSuccess: boolean }>()
)

export const loadSeachCouponSuccess = createAction(
    '[Search Coupon] Load Search Coupon Success',
    props<{ data: ICouponHomeModel[] }>()
)

export const saveSeachKeyword = createAction(
    '[Search Coupon] Save Search Keyword',
    props<{ keyword: string }>()
)

export const saveDefaultSeachKeyword = createAction(
    '[Search Coupon] Save Default Search Keyword',
    props<{ defaultSearchKeyword: string[] }>()
)

export const saveDefaultSeachKeywordIndex = createAction(
    '[Search Coupon] Save Default Search Keyword Index',
    props<{ defaultSearchKeywordIndex: number }>()
)

export const clearSearchCoupon = createAction(
    '[Search Coupon] Clear Search Coupon'
)

export const clearSearchCouponResult = createAction(
    '[Search Coupon] Clear Search Coupon Result'
)